import React from 'react';
import {
    Box,
    Button,
    Typography,
    Step,
    StepContent,
    StepLabel,
    IconButton,
    CircularProgress,
    useMediaQuery
} from '@mui/material';
import { UploadFile, CloudUpload, SkipNext, Delete } from '@mui/icons-material';
import ButtonWithIcon from '../../common/button/Button';
import {
    CustomStepper,
    CustomLinearProgress,
    CustomCircularProgress,
    uploadBoxStyle,
    uploadButtonStyle,
    stepButtonStyle,
    additionalFileButtonStyle,
    uploadSelectedFilesButtonStyle,
    resetButtonStyle,
} from './Style';

interface UploadStepperProps {
    activeStep: number;
    getRootProps: () => any;
    getInputProps: () => any;
    uploadFilesStatus: string;
    uploadFilesProgress: number;
    getAdditionalFilesRootProps: () => any;
    getAdditionalFilesInputProps: () => any;
    additionalFiles: File[];
    setAdditionalFiles: (files: File[] | ((prevFiles: File[]) => File[])) => void;
    uploadAdditionalFileStatus: string;
    additionalProgress: number;
    handleUploadAdditionalFiles: () => void;
    isUploadFinished: boolean;
    handleResetUpload: () => void;
    anotherButtonText: string;
    loading?: boolean;
    handleNext?: () => void;
}

const UploadStepper: React.FC<UploadStepperProps> = ({
    activeStep,
    getRootProps,
    getInputProps,
    uploadFilesStatus,
    uploadFilesProgress,
    getAdditionalFilesRootProps,
    getAdditionalFilesInputProps,
    additionalFiles,
    setAdditionalFiles,
    uploadAdditionalFileStatus,
    additionalProgress,
    handleUploadAdditionalFiles,
    isUploadFinished,
    handleResetUpload,
    anotherButtonText,
    loading,
    handleNext,
}) => {
    const isSmallScreen = useMediaQuery('(min-width: 999px)');

    const handleRemoveAdditionalFile = (fileToRemove: string) => {
        setAdditionalFiles((prevFiles: any[]) =>
            prevFiles.filter((file) => file.name !== fileToRemove)
        );
    };

    return (
        <Box sx={{ width: '100%' }}>
            <CustomStepper activeStep={activeStep} orientation="vertical">
                <Step expanded={true}>
                    <StepLabel sx={{ color: '#0B4566' }}></StepLabel>
                    <StepContent>
                        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', width: '100%', gap: 1, flexWrap: 'wrap' }}>
                            <Box {...getRootProps()} sx={uploadBoxStyle}>
                                <input {...getInputProps()} />
                                <Typography sx={{ fontSize: isSmallScreen ? '14px' : '12px' }}>
                                    Click the upload button or drop your file here to upload
                                </Typography>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    startIcon={<CloudUpload />}
                                    onClick={() => { }}
                                    disabled={activeStep > 0}
                                    sx={uploadButtonStyle}
                                >
                                    Upload
                                </Button>
                                {uploadFilesStatus === 'Uploading...' && (
                                    <CustomLinearProgress
                                        sx={{
                                            width: '300px',
                                            height: 8,
                                            mt: 1,
                                            borderRadius: 5,
                                        }}
                                        value={uploadFilesProgress}
                                        variant="determinate"
                                    />
                                )}
                                <Typography sx={{ mt: 2, fontSize: 11 }}>{uploadFilesStatus}</Typography>
                            </Box>
                        </Box>
                    </StepContent>
                </Step>

                <Step expanded={true}>
                    <StepLabel sx={{ color: '#0B4566' }}>
                        <Box display='flex' flexDirection='row' justifyContent='flex-start' alignItems='center' flexWrap='wrap' width='100%'>
                            <Box width='fit-content'>
                                Upload additional related files
                            </Box>
                            <Box display='flex' flexDirection='row'>
                                <Box width='fit-content'>
                                    <ButtonWithIcon
                                        variant='outlined'
                                        icon={<SkipNext />}
                                        onClick={handleNext}
                                        disabled={activeStep !== 1}
                                        text='Skip'
                                        sx={stepButtonStyle(activeStep, 1, isSmallScreen)}
                                    />
                                </Box>
                                <Box {...getAdditionalFilesRootProps()}>
                                    <input {...getAdditionalFilesInputProps()} />
                                    <ButtonWithIcon
                                        variant='outlined'
                                        icon={<UploadFile />}
                                        onClick={() => { }}
                                        disabled={activeStep !== 1}
                                        text='Select file'
                                        sx={additionalFileButtonStyle(activeStep, 1, isSmallScreen)}
                                    />
                                </Box>
                                {additionalFiles.length > 0 && (
                                    <>
                                        <Typography variant="body2" sx={{ marginLeft: 2 }}>
                                            {additionalFiles.length} files have been selected
                                        </Typography>
                                        {uploadAdditionalFileStatus === "Uploading..." && loading && (
                                            <Box display="flex" justifyContent="center" alignItems="center" className="custom-circular-progress">
                                                <CustomCircularProgress variant="determinate" value={additionalProgress} />
                                            </Box>
                                        )}
                                    </>
                                )}
                            </Box>
                        </Box>
                    </StepLabel>
                    <StepContent>
                        <Box display='flex' flexDirection='column' justifyContent='flex-start' alignItems='flex-start'>
                            <Box display='flex' flexDirection='column' justifyContent='flex-start' alignItems='flex-start'>
                                {additionalFiles.map((file, index) => (
                                    <Box key={index} display='flex' alignItems='center'>
                                        <Typography sx={{ color: '#86A2B3', fontSize: 12 }}>{file.name}</Typography>
                                        <IconButton onClick={() => handleRemoveAdditionalFile(file.name)} size="small" title='Remove file'>
                                            <Delete fontSize="small" />
                                        </IconButton>
                                    </Box>
                                ))}
                            </Box>
                            <Box marginTop={2}>
                                {additionalFiles.length > 0 && (
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        startIcon={<UploadFile />}
                                        onClick={handleUploadAdditionalFiles}
                                        disabled={activeStep !== 1}
                                        sx={uploadSelectedFilesButtonStyle(additionalFiles.length)}
                                    >
                                        Upload selected files
                                    </Button>
                                )}
                            </Box>
                        </Box>
                    </StepContent>
                </Step>

                <Step expanded={true}>
                    <StepLabel sx={{ color: '#0B4566' }}>
                        <Box display='flex' flexDirection='row' justifyContent='flex-start' alignItems='center'>
                            <Box>
                                Upload Status
                            </Box>
                            <Box>
                                {uploadAdditionalFileStatus === 'Uploading...' ? (
                                    <CircularProgress size={24} sx={{ marginLeft: 2 }} />
                                ) : (
                                    <Typography variant="body2" sx={{ marginLeft: 2, color: uploadAdditionalFileStatus === 'Success' ? 'green' : 'red' }}>
                                        {uploadAdditionalFileStatus}
                                    </Typography>
                                )}
                            </Box>
                        </Box>
                    </StepLabel>
                    <StepContent sx={{ textAlign: 'left' }}>
                        {activeStep === 2 && (
                            <ButtonWithIcon
                                variant='outlined'
                                icon={null}
                                onClick={handleResetUpload}
                                text={anotherButtonText}
                                sx={resetButtonStyle}
                            />
                        )}
                    </StepContent>
                </Step>
            </CustomStepper>
        </Box>
    );
}

export default UploadStepper;
