import React from 'react';
import { Backdrop, Button, Dialog, DialogActions, DialogContent, DialogTitle, useMediaQuery, useTheme } from '@mui/material';

interface InvalidUserProps {
  open: boolean;
  message: string;
  onAction: () => void;
}

const InvalidUser: React.FC<InvalidUserProps> = ({ open, message, onAction }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={open}
    >
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={onAction}
      >
        <DialogTitle>Error</DialogTitle>
        <DialogContent>
          {message}
        </DialogContent>
        <DialogActions>
          <Button onClick={onAction} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Backdrop>
  );
};

export default InvalidUser;
