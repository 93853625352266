import React from 'react';
import { useUploadView } from '../../../context/upload-context/UploadContext';
import UploadWrapper from './UploadWrapper';
import { fileAccept, additionalFileAccept } from './UploadMenu';
import { NutrientType } from '../../../pages/upload-page/UploadLib';

const NutrientOrganicPollutant: React.FC = () => {
    const { loading, downloadTemplateNutrientSurveyResult, uploadNutrientSurvey, uploadAdditionalNutrientSurvey } = useUploadView();

    const handleDownloadTemplate = () => {
        downloadTemplateNutrientSurveyResult(NutrientType.OrganicPollutan)
    }

    const handleUploadFile = async (file: File): Promise<void> => {
        await uploadNutrientSurvey(file, NutrientType.OrganicPollutan);
    };

    const handleUploadAdditionalFiles = async (files: File[]): Promise<void> => {
        await uploadAdditionalNutrientSurvey(files);
    };

    return (
        <UploadWrapper
            title="Nutrient Survey Result - PAH, TPH, O&G"
            templateDownload={handleDownloadTemplate}
            uploadFile={handleUploadFile}
            uploadAdditionalFile={handleUploadAdditionalFiles}
            fileAccept={fileAccept}
            additionalFileAccept={additionalFileAccept}
            anotherButtonText="Upload Another PAH, TPH, O&G Survey"
            loading={loading}
        />
    );
};

export default NutrientOrganicPollutant;