import React, { useState } from 'react';
import SampleInfoUpload from './SampleInfo';
import TabUpload from './TabUpload';
import { Box } from '@mui/material';
import PlanktonSurvey from './PlanktonSurvey';
import NutrientSurvey from './NutrientSurvey';
import NutrientHeavyMetals from './NutrientHeavyMetals';
import SedimentQuality from './SedimentQuality';
import NutrientOrganicPollutant from './NutrientOrganicPollutant';

export type Tab = {
    label: string;
    components: JSX.Element[];
}

export type UploadMenuProps = {}

export type UploadProps = {
    tabs: Tab[];
}

export const fileAccept = {
    'application/vnd.ms-excel': ['.xls'],
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx']
}

export const additionalFileAccept = {
    'application/pdf': ['.pdf'],
    'application/vnd.ms-excel': ['.xls'],
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
    'application/msword': ['.doc'],
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
    'text/plain': ['.txt'],
    'text/csv': ['.csv'],
    'image/png': ['.png'],
    'image/jpeg': ['.jpg', '.jpeg'],
    'application/zip': ['.zip']
}

const UploadMenu: React.FC<UploadMenuProps> = () => {
    
    const [tabs] = useState<Tab[]>([
        { label: 'Sample Info', components: [<SampleInfoUpload key="sample-info" />] },
        { label: 'Plankton Survey Result', components: [<PlanktonSurvey key="plankton-survey" />] },
        { 
            label: 'Nutrient Survey Result', 
            components: [
                <NutrientSurvey key="nutrient-survey" />,
                <NutrientHeavyMetals key="heavy-metals-survey" />,
                <NutrientOrganicPollutant key="organic-pollutant-survey" />,
            ] 
        },
        { label: 'Sediment Quality Result', components: [<SedimentQuality key="sediment-quality" />] },
    ]);

    return (
        <Box
            sx={{
                position: 'relative',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                boxSizing: 'border-box',
                zIndex: 2,
                backgroundColor: 'white',
            }}
        >
            <TabUpload tabs={tabs} />
        </Box>
    )
}

export default UploadMenu;
