import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { AuthProvider } from './context/auth-context/AuthContext';
import { MapViewProvider } from './context/map-context/mapContext';
import { ContactProvider } from './context/contact-context/contactContext';
import { TimeseriesViewProvider } from './context/timeseries-context/timeseriesContext';
import { EventViewProvider } from './context/event-context/EventContext';
import { DocumentViewProvider } from './context/document-context/DocumentContext';
import { UploadViewProvider } from './context/upload-context/UploadContext';
import { OidcProvider } from '@axa-fr/react-oidc';
import { DataPointViewProvider } from './context/data-point-context/DataPointContext';
import { BrowserRouter as Router } from 'react-router-dom';
import './index.css';

const environment = window.location.origin;
const isProduction = environment === 'https://asn.infoatsea.com';

const oidcConfiguration = {
  client_id: isProduction
    ? 'b4266eba-50b6-44ed-a6dc-b8a1992a580b'
    : '08cab292-9a3d-424a-a133-1360f2d51278',
  redirect_uri: `${environment}/auth/callback`,
  silent_redirect_uri: `${environment}/auth/silent-callback`,
  scope: isProduction
    ? 'api://b4266eba-50b6-44ed-a6dc-b8a1992a580b/Files.Read offline_access'
    : 'api://08cab292-9a3d-424a-a133-1360f2d51278/Files.Read offline_access',
  authority: 'https://login.microsoftonline.com/common/v2.0',
  service_worker_only: false,
  automaticSilentRenew: true,
  loadUserInfo: true,
};

// Wrapper for nested providers to reduce hierarchy depth
const ProviderWrapper: React.FC<React.PropsWithChildren<{}>> = ({ children }) => (
  <AuthProvider>
    <MapViewProvider>
      <TimeseriesViewProvider>
        <UploadViewProvider>
          <DocumentViewProvider>
            <EventViewProvider>
              <DataPointViewProvider>
                <ContactProvider>
                  {children}
                </ContactProvider>
              </DataPointViewProvider>
            </EventViewProvider>
          </DocumentViewProvider>
        </UploadViewProvider>
      </TimeseriesViewProvider>
    </MapViewProvider>
  </AuthProvider>
);

const root = ReactDOM.createRoot(
  document.getElementById('root')!
);

root.render(
  <React.StrictMode>
    <Router>
      <OidcProvider configuration={oidcConfiguration}>
        <ProviderWrapper>
          <App />
        </ProviderWrapper>
      </OidcProvider>
    </Router>
  </React.StrictMode>
);
