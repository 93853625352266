import React, { useState, createContext, useContext, useEffect } from 'react';
import { useFetchWithAuth } from '../../hooks/useFetchWithAuth';
import { dataType } from '../../pages/map-view-page/MapViewLib';
import { useAuth, VariantType } from '../auth-context/AuthContext';
import { createDownloadLink } from '../../utils/Utils';

interface DataPointViewContextType {
    loading: boolean;
    getStationDetails: (dataType: string) => void;
    selectedDataType: string;
    setSelectedDataType: (val: string) => void;
    listStationsDetails: any;
    stationFilter: string;
    setStationFilter: (val: string) => void;
    downLoadDataPoint: (val: string) => void;
}

const DataPointContext = createContext<DataPointViewContextType | undefined>(undefined);

export const DataPointViewProvider = ({ children }: { children: React.ReactNode }) => {
    
    const { baseUrl, user, setSnackbarOpen, setSnackbarMessage, setVariant } = useAuth();
    const fetchWithAuth = useFetchWithAuth();
    const [loading, setLoading] = useState<boolean>(true);

    const [stationFilter, setStationFilter] = useState<string>('');
    const [selectedDataType, setSelectedDataType] = useState<string>(dataType.WaterQuality);
    const [listStationsDetails, setListStationsDetails] = useState<any>([]);

    const getStationDetails = async (dataType: string) => {
        try {
            setLoading(true);
            const response = await fetchWithAuth(`/api/Stations/Details?dataType=${dataType}`, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error(`Failed to fetch station data for ${dataType}`);
            }

            const data = await response.json();
            setListStationsDetails(data)
        } catch (err) {
            setSnackbarMessage('Failed to fetch the data point.');
            setSnackbarOpen(true);
            setVariant(VariantType.error);
        } finally {
            setLoading(false);
        }
    };
    
    useEffect(() => {
        if (baseUrl && user?.token) {
            getStationDetails(selectedDataType);
        }
    }, [selectedDataType, baseUrl, user?.token]);
    
    const downLoadDataPoint = async (dataType: string) => {
        try {
            const response = await fetchWithAuth(`/api/DataPoints/download?dataType=${dataType}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            if (!response.ok) {
                throw new Error('Failed to download document');
            }
            let filename = `${dataType}_data_point`;
            const blob = await response.blob();
            const downloadUrl = window.URL.createObjectURL(blob);
            createDownloadLink(downloadUrl, filename);
            window.URL.revokeObjectURL(downloadUrl);
        } catch (error) {
            console.error('Download failed:', error);
            setSnackbarMessage('Failed to download document. Please try again.');
            setSnackbarOpen(true);
            setVariant(VariantType.error);
        }
    };

    return (
        <DataPointContext.Provider
            value={{
                loading,
                getStationDetails,
                selectedDataType,
                setSelectedDataType,
                listStationsDetails,
                stationFilter,
                setStationFilter,
                downLoadDataPoint
            }}
        >
            {children}
        </DataPointContext.Provider>
    );
};

export const useDataPointView = () => {
    const context = useContext(DataPointContext);
    if (context === undefined) {
        throw new Error('useDataPointView must be used within a DataPointViewProvider');
    }
    return context;
};
