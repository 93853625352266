import { useState } from 'react';
import { formatDigitValue } from '../utils/Utils';
import { addThresholdsToSeries, calculateInterval, calculateNameGap, chartTypeSeries, createStationColorMapping, createToolboxFeatures, formatXAxisLabel, getSeriesName, handleShowWarningMessage, renderTooltip, toolboxIcon } from '../utils/TimeseriesUtils';
import { useAuth } from '../context/auth-context/AuthContext';
import { useTimeseriesView } from '../context/timeseries-context/timeseriesContext';

export const useChartOptions = (
    timeseriesData: any,
    selectedDataType: string,
    selectedStations: string[],
    selectedParameters: string[],
    showThreshold: boolean,
    isMoveAxis: boolean,
    setShowThreshold: (val: boolean) => void,
    setIsMoveAxis: (val: boolean) => void,
    setIsShowDataView: (val: boolean) => void,
    exportCsv: () => void,
    exportRaw: () => void,
    handleZoomChange: (val: any) => void,
    zoomRange: any
) => {
    const { user } = useAuth();
    const [chartType, setChartType] = useState<'line' | 'bar'>('line');
    const { statisticTypeList } = useTimeseriesView();

    const handleShowThresholdChange = () => {
        setShowThreshold(!showThreshold);
    };

    const handleShowDataView = () => {
        setIsShowDataView(true);
    };

    const moveAxis = () => {
        setIsMoveAxis(!isMoveAxis);
    };

    const getOption = (): any => {
        const stationColorMapping = createStationColorMapping(selectedStations);

        const isShowWarningMessage = handleShowWarningMessage(selectedStations, selectedParameters, timeseriesData);
        if (isShowWarningMessage) {
            return isShowWarningMessage;
        }

        const parameterUnits = selectedParameters
            .map(parameter => {
                const parameterData = timeseriesData.find((data: any) =>
                    selectedStations.includes(data.station) && data.parameter === parameter
                );
                return parameterData ? parameterData?.unit : null;
            })
            .filter(unit => unit !== null || unit !== undefined);

        const allUnitsSame = parameterUnits.every((unit, _, arr) => unit === arr[0]);

        const xAxisOptions = {
            type: 'time',
            z: 0,
            zlevel: 0,
            axisLabel: {
                formatter: formatXAxisLabel(),
                interval: 'auto'
            },
            splitNumber: 3,
            axisLine: {
                show: true,
                onZero: false
            },
            position: 'bottom',
            boundaryGap: false,
        };

        let yAxisOptions: any = [];
        let series: any = [];

        let globalMin = Infinity;
        let globalMax = -Infinity;


        selectedParameters?.forEach((parameter, index) => {
            const parameterData = timeseriesData.filter((data: any) =>
                selectedStations.includes(data.station) && data.parameter === parameter
            );

            if (parameterData.length > 0) {
                const yAxisName = `${parameterData[0].parameter} (${parameterData[0].unit})`;

                let axisMin = Infinity;
                let axisMax = -Infinity;

                parameterData.forEach((data: any) => {
                    const dataValues = data.data.map((d: any) => d.value);
                    axisMin = Math.min(axisMin, ...dataValues);
                    axisMax = Math.max(axisMax, ...dataValues);

                    if (axisMin === 0 && axisMax === 0) {
                        axisMin = 0;
                        axisMax = 8;
                    } else {
                        if (showThreshold) {
                            parameterData.forEach((data: any) => {
                                if (data?.minimumThreshold < axisMin) {
                                    axisMin = data.minimumThreshold - 1;
                                }
                                if (data?.maximumThreshold > axisMax) {
                                    axisMax = data.maximumThreshold + 1;
                                }
                            });
                        }
                    }
                });

                const interval = calculateInterval(axisMin, axisMax, 8);
                axisMin -= interval;
                axisMax += interval;

                globalMin = Math.min(globalMin, axisMin);
                globalMax = Math.max(globalMax, axisMax);

                if (!isMoveAxis) {
                    const yAxisPosition = index % 2 === 0 ? 'left' : 'right';
                    const nameGap = calculateNameGap(axisMax);

                    yAxisOptions.push({
                        type: 'value',
                        name: `${yAxisName}`,
                        position: yAxisPosition,
                        nameGap: nameGap,
                        nameRotate: 90,
                        nameLocation: yAxisPosition === 'left' ? 'middle' : 'middle',
                        axisLine: {
                            show: true,
                        },
                        axisLabel: {
                            show: true,
                            formatter: (value: number) => formatDigitValue(value, selectedDataType),
                            interval: interval,
                        },
                        axisTick: {
                            show: true,
                            alignWithLabel: true,
                        },
                        splitLine: {
                            show: true,
                            lineStyle: {
                                color: '#ccc',
                                width: 1,
                                type: 'solid',
                            },
                        },
                        splitNumber: 8,
                        min: axisMin,
                        max: axisMax,
                        interval: interval,
                        z: 1,
                        zlevel: 0,
                    });
                }

                parameterData.forEach((data: any, chartIndex: number) => {
                    const seriesData = data.data.map((d: any) => ({
                        value: [
                            new Date(d.dateTime).getTime(),
                            d.value,
                        ],
                        symbol: index === 1 ? 'circle' : 'rect',
                    }));

                    const seriesName = getSeriesName(data, statisticTypeList);

                    let seriesItem: any = {
                        name: seriesName,
                        type: chartType,
                        data: seriesData,
                        itemStyle: {
                            color: stationColorMapping[chartIndex],
                            opacity: index === 1 ? 0.7 : 1,
                        },
                        symbolSize: 5,
                        yAxisIndex: isMoveAxis ? 0 : index,
                        z: 3,
                        zlevel: 1,
                    };

                    seriesItem = chartTypeSeries(seriesItem, chartType, index);

                    if (showThreshold) {
                        addThresholdsToSeries(seriesItem, data);
                    }

                    series.push(seriesItem);
                });
            }
        });

        if (isMoveAxis) {
            yAxisOptions.push({
                type: 'value',
                position: 'left',
                axisLine: {
                    show: true,
                },
                axisLabel: {
                    show: true,
                    formatter: (value: number) => formatDigitValue(value, selectedDataType),
                    interval: 'auto',
                },
                axisTick: {
                    show: true,
                    alignWithLabel: true,
                },
                splitLine: {
                    show: true,
                    lineStyle: {
                        color: '#ccc',
                        width: 1,
                        type: 'solid',
                    },
                },
                splitNumber: 8,
                min: globalMin,
                max: globalMax,
                alignTicks: true,
                z: 1,
                zlevel: 0,
            });
        }

        const userRoles = Array.isArray(user?.roles) ? user.roles : [user?.roles];

        const toolboxFeatures = createToolboxFeatures(
            userRoles,
            selectedDataType,
            showThreshold,
            handleShowThresholdChange,
            handleShowDataView,
            exportCsv,
            exportRaw,
            moveAxis,
            toolboxIcon,
            setChartType,
            selectedParameters,
            allUnitsSame,
        );

        return {
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'line',
                    label: {
                        show: true,
                    },
                },
                formatter: (params: any) => renderTooltip(params, selectedDataType),
            },
            toolbox: {
                orient: 'horizontal',
                right: 10,
                top: 'top',
                feature: toolboxFeatures,
            },
            dataZoom: [
                {
                    type: 'slider',
                    start: zoomRange.start,
                    end: zoomRange.end,
                    bottom: '20%',
                    height: 10,
                    onChange: handleZoomChange,
                },
                {
                    type: 'inside',
                    xAxisIndex: 0,
                    onChange: handleZoomChange,
                },
            ],
            grid: {
                top: 50,
                bottom: 150,
                left: '10%',
                right: '10%',
                containLabel: true,
            },
            xAxis: xAxisOptions,
            yAxis: yAxisOptions,
            series: selectedParameters.length > 0 ? series : [],
            legend: {
                orient: 'horizontal',
                left: 'center',
                bottom: '5%',
                itemWidth: 20,
                itemHeight: 14,
                symbolKeepAspect: true,
                textStyle: {
                    fontSize: 12,
                },
            },
        };
    };

    return { getOption };
};
