import React from 'react';
import { Alert, Box, Snackbar } from '@mui/material';
import fishFarm from '../../asset/Images/fish-farm.png';
import LoginContainer from '../../components/layout/login-container/LoginContainer';
import { useAuth } from '../../context/auth-context/AuthContext';
import { useNavigate } from 'react-router-dom';
import InvalidUser from '../../components/feature/notification/InvalidUser';
import Notification from '../../components/feature/notification/Notification';

const LoginPage = () => {
  const {
    snackbarOpen,
    snackbarMessage,
    openNotification,
    variant,
    setSnackbarOpen,
    setOpenNotification,
    setUser,
  } = useAuth();

  const alertSeverity: 'success' | 'info' | 'warning' | 'error' = (
    ['success', 'info', 'warning', 'error'].includes(variant) ? variant : 'info'
  ) as 'success' | 'info' | 'warning' | 'error';

  const handleDialogAction = () => {
    setOpenNotification(false);
    sessionStorage.clear();
    localStorage.clear();
    setUser(null);
    window.location.reload();
  };

  return (
    <Box
      sx={{
        position: 'absolute',
        width: '100%',
        height: '100vh',
        backgroundImage: `linear-gradient(90deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.2) 100%), url(${fishFarm})`,
        backgroundPosition: 'top left',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        zIndex: 0,
        top: 0,
        left: 0,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <LoginContainer />
      <Notification
        open={snackbarOpen}
        message={snackbarMessage}
        severity={alertSeverity}
        onClose={() => setSnackbarOpen(false)}
      />
      <InvalidUser
        open={openNotification}
        message={snackbarMessage}
        onAction={handleDialogAction}
      />
    </Box>
  );
};

export default LoginPage;
