import React from 'react'
import { Box, InputAdornment, TextField, useMediaQuery } from '@mui/material';
import { Search } from '@mui/icons-material';
import { useDataPointView } from '../../../context/data-point-context/DataPointContext';

const StationFilter = () => {
    
    const { stationFilter, setStationFilter } = useDataPointView()
    const isSmallScreen = useMediaQuery('(min-width: 999px)');

    const handleFilterChange = React.useCallback((event: { target: { value: string; }; }) => {
        setStationFilter(event.target.value.toLowerCase());
    }, [setStationFilter]);

    const startAdornment = (
        <InputAdornment position="start">
            <Search sx={{ color: '#163046', fontSize: isSmallScreen ? '24px' : '16px' }} />
        </InputAdornment>
    );

    return (
        <TextField
                id="search-textfield"
                placeholder='Search...'
                variant="outlined"
                value={stationFilter}
                onChange={handleFilterChange}
                InputProps={{
                    startAdornment,
                    style: {
                        padding: 5,
                        height: 38,
                        borderRadius: 5,
                        fontSize: isSmallScreen ? '14px' : '12px'
                    }
                }}
            />
    )
}

export default StationFilter;
