import React, { Fragment } from 'react'
import LandingPageBanner from '../../components/layout/landing-page/LandingPageBanner'
import LandingPageMonitoring from '../../components/layout/landing-page/LandingPageMonitoring'
import LandingPageDescription from '../../components/layout/landing-page/LandingPageDescription'
import LandingPageBottom from '../../components/layout/landing-page/LandingPageBottom'
import Footer from '../../components/layout/footer/Footer'

const LandingPage = () => {
  return (
    <Fragment>
      <LandingPageBanner />
      <LandingPageMonitoring />
      <LandingPageDescription />
      <LandingPageBottom />
      <Footer />
    </Fragment>
  )
}

export default LandingPage