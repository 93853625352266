import React, { useState } from 'react';
import { Box } from '@mui/material';
import UploadStepper from '../../feature/upload-stepper/UploadStepper';
import { useDropzone } from 'react-dropzone';
import UploadHeader from './UploadHeader';

interface UploadWrapperProps {
    title: string;
    templateDownload: () => void;
    uploadFile: (file: File) => Promise<void>;
    uploadAdditionalFile: (files: File[]) => Promise<void>;
    fileAccept: { [key: string]: string[] };
    additionalFileAccept: { [key: string]: string[] };
    anotherButtonText: string;
    loading?: boolean;
}

const UploadWrapper: React.FC<UploadWrapperProps> = ({
    title,
    templateDownload,
    uploadFile,
    uploadAdditionalFile,
    fileAccept,
    additionalFileAccept,
    anotherButtonText,
    loading,
}) => {
    const [files, setFiles] = useState<File[]>([]);
    const [uploadFileStatus, setUploadFileStatus] = useState<string>('');
    const [uploadFileProgress, setUploadFileProgress] = useState<number>(0);
    const [additionalFiles, setAdditionalFiles] = useState<File[]>([]);
    const [uploadAdditionalStatus, setUploadAdditionalStatus] = useState<string>('');
    const [additionalProgress, setAdditionalProgress] = useState<number>(0);
    const [activeStep, setActiveStep] = useState<number>(0);
    const [completedSteps, setCompletedSteps] = useState<number[]>([]);
    const [isUploadFinished, setIsUploadFinished] = useState<boolean>(false);

    const { getRootProps, getInputProps } = useDropzone({
        onDrop: acceptedFiles => {
            setFiles(acceptedFiles);
            if (acceptedFiles[0] && activeStep === 0) {
                handleUploadFile(acceptedFiles[0]);
            }
        },
        accept: fileAccept,
        multiple: false,
        disabled: activeStep !== 0
    });

    const handleUploadFile = async (file: File) => {
        if (!file) {
            setUploadFileStatus('No file selected.');
            return;
        }

        setUploadFileStatus('Uploading...');
        setUploadFileProgress(0);
        let timer = window.setInterval(() => {
            setUploadFileProgress(oldProgress => {
                const newProgress = Math.min(oldProgress + 10, 100);
                if (newProgress === 100) {
                    clearInterval(timer);
                }
                return newProgress;
            });
        }, 100);

        try {
            await uploadFile(file);
            setUploadFileStatus(`Upload file ${file.name} completed!`);
            setIsUploadFinished(true);
            handleNext();
        } catch (error) {
            console.error('Upload error:', error);
            setUploadFileStatus('Failed to upload file. Please try again.');
            clearInterval(timer);
            setUploadFileProgress(0);
        }
    };

    const { getRootProps: getAdditionalFilesRootProps, getInputProps: getAdditionalFilesInputProps } = useDropzone({
        onDrop: acceptedFiles => {
            if (activeStep === 1) {
                setAdditionalFiles(acceptedFiles);
            }
        },
        accept: additionalFileAccept,
        multiple: true,
        disabled: activeStep !== 1
    });

    const handleUploadAdditionalFiles = async () => {
        setUploadAdditionalStatus('Uploading...');

        setAdditionalProgress(0);
        let timer = window.setInterval(() => {
            setAdditionalProgress(oldProgress => {
                const newProgress = Math.min(oldProgress + 10, 100);
                if (newProgress === 100) {
                    clearInterval(timer);
                    setUploadAdditionalStatus(`Success`);
                    setIsUploadFinished(true);
                    handleNext();
                }
                return newProgress;
            });
        }, 100);
        try {
            await uploadAdditionalFile(additionalFiles);
        } catch (error) {
            console.error('Upload error:', error);
            setUploadAdditionalStatus('Failed to upload file. Please try again.');
            clearInterval(timer);
            setAdditionalProgress(0);
        }
    };

    const handleNext = () => {
        const nextStep = activeStep + 1;
        if (!completedSteps.includes(activeStep)) {
            setCompletedSteps([...completedSteps, activeStep]);
        }
        setActiveStep(nextStep);
    };

    const handleResetUpload = () => {
        setUploadFileStatus('');
        setUploadAdditionalStatus('');
        setFiles([]);
        setAdditionalFiles([]);
        setActiveStep(0);
        setCompletedSteps([]);
        setIsUploadFinished(false);
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                padding: 2,
                borderRadius: 1,
                backgroundColor: 'white'
            }}
        >
            <UploadHeader title={title} templateDownload={templateDownload} />
            <UploadStepper
                activeStep={activeStep}
                getRootProps={getRootProps}
                getInputProps={getInputProps}
                uploadFilesStatus={uploadFileStatus}
                uploadFilesProgress={uploadFileProgress}
                getAdditionalFilesRootProps={getAdditionalFilesRootProps}
                getAdditionalFilesInputProps={getAdditionalFilesInputProps}
                additionalFiles={additionalFiles}
                setAdditionalFiles={setAdditionalFiles}
                uploadAdditionalFileStatus={uploadAdditionalStatus}
                additionalProgress={additionalProgress}
                handleUploadAdditionalFiles={handleUploadAdditionalFiles}
                isUploadFinished={isUploadFinished}
                handleResetUpload={handleResetUpload}
                anotherButtonText={anotherButtonText}
                loading={loading}
                handleNext={handleNext}
            />
        </Box>
    )
}

export default UploadWrapper;
