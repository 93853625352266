import React, { createContext, useContext, ReactNode, useState, useEffect } from 'react';
import { useAuth, VariantType } from '../auth-context/AuthContext';
import { createDownloadLink, sanitizeFilename } from '../../utils/Utils';
import { useFetchWithAuth } from '../../hooks/useFetchWithAuth';
import { DataType, Observations, ParameterType, StationInfo, StatisticType } from '../../pages/time-series-page/TimeseriesLib';

interface TimeseriesViewContextType {
    loading: boolean;
    error: string | null;
    fetchObservationsData: (startDate: string, endDate: string, selectedDataType: string, selectedStation: string[], selectedParameters: string[], setObservations: (val: any) => void) => void;
    exportObservationData: (startDate: string, endDate: string, selectedDataType: string, selectedStation: string[], selectedParameters: string[], isRawData?: boolean) => void;
    statisticTypeList: StatisticType[];
    dataTypeList: DataType[];
    fetchMonthlyStatisticData: (startDate: string, endDate: string, selectedDataType: string, selectedStation: string[], selectedParameters: string[], selectedStatisticType: number, setStatisticData: (val: any) => void) => void;
    exportMonthlyStatisticData: (startDate: string, endDate: string, selectedDataType: string, selectedStation: string[], selectedParameters: string[], selectedStatisticType: number) => void;
    stationInfo: any;
    setStationInfo: (val: any) => void;
    isFullscreen: boolean;
    setIsFullscreen: (val: boolean) => void;
    fetchAreaList: (val: string, setAreaList: (val: string[]) => void) => void;
    fetchStationList: (dataType: string, area: string, setStationList: (val: string[]) => void) => void;
    fetchParameterList: (val: string[], setParameterList: (val: ParameterType[])=> void) => void;
    fetchStatisticTypeList: (val: string) => void;
    fetchDataTypeList: () => void;
}

const TimeseriesContext = createContext<TimeseriesViewContextType | undefined>(undefined);

export const TimeseriesViewProvider = ({ children }: { children: ReactNode }) => {

    const { baseUrl, user, setSnackbarOpen, setSnackbarMessage, setVariant } = useAuth();
    const fetchWithAuth = useFetchWithAuth()
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const [statisticTypeList, setStatisticTypeList] = useState<StatisticType[]>([]);
    const [dataTypeList, setDataTypeList] = useState<DataType[]>([])
    const [stationInfo, setStationInfo] = useState<StationInfo>({
        selectedPeriod: 'Last 24 Hours',
        selectedDataType: 'WaterQuality',
        selectedStation: [],
        selectedParameters: [],
        selectedArea: ""
    });
    const [isFullscreen, setIsFullscreen] = useState<boolean>(false)

    const fetchObservationsData = async (
        startDate: string,
        endDate: string,
        selectedDataType: string,
        selectedStation: string[],
        selectedParameters: string[],
        setObservations: (val: Observations[]) => void
    ) => {
        try {
            setLoading(true);
            setError(null);

            const response = await fetchWithAuth('/api/Observations', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    startDate,
                    endDate,
                    dataType: selectedDataType,
                    stations: selectedStation.join('|'),
                    parameters: selectedParameters.join('|')
                })
            });

            if (response.ok) {
                const data = await response.json();

                if (Array.isArray(data)) {
                    setObservations(data);
                } else {
                    setObservations([]);
                }
            } else {
                setError(`Error fetching data: ${response.status}`);
                setObservations([]);
            }
        } catch (err) {
            setError(err instanceof Error ? err.message : 'An unknown error occurred');
            setObservations([]);
        } finally {
            setLoading(false);
        }
    };

    const exportObservationData = async (
        startDate: string,
        endDate: string,
        selectedDataType: string,
        selectedStation: string[],
        selectedParameters: string[],
        isRawData: boolean = false
    ) => {

        try {
            const response = await fetchWithAuth(`/api/Observations/exportascsv?isRawData=${isRawData}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    "startDate": startDate,
                    "endDate": endDate,
                    "dataType": selectedDataType,
                    "stations": selectedStation.join('|'),
                    "parameters": selectedParameters.join('|')
                })
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const blob = await response.blob();
            const contentDisposition = response.headers.get('content-disposition');
            let filename = 'Observations.zip';
            if (contentDisposition) {
                const matches = contentDisposition.match(/filename\*?=(?:UTF-8''([^;]+)|\"([^\"]+)\")/);
                if (matches) {
                    filename = decodeURIComponent(matches[1] || matches[2]);
                }
            }
            filename = sanitizeFilename(filename);
            const downloadUrl = window.URL.createObjectURL(blob);
            createDownloadLink(downloadUrl, filename);
            window.URL.revokeObjectURL(downloadUrl);
            setSnackbarMessage('Observation data successfully downloaded');
            setSnackbarOpen(true);
            setVariant(VariantType.success);
        } catch (error) {
            console.error('Download failed:', error);
            setSnackbarMessage('Download observation data failed');
            setSnackbarOpen(true);
            setVariant(VariantType.error);
        }
    };

    const fetchMonthlyStatisticData = async (
        startDate: string,
        endDate: string,
        selectedDataType: string,
        selectedStation: string[],
        selectedParameters: string[],
        selectedStatisticType: number,
        setStatisticData: (val: Observations[]) => void
    ) => {

        try {
            setLoading(true);
            const response = await fetchWithAuth('/api/MonthlyStatistics', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    "startDate": startDate,
                    "endDate": endDate,
                    "dataType": selectedDataType,
                    "stations": selectedStation.join('|'),
                    "parameters": selectedParameters.join('|'),
                    "statisticType": selectedStatisticType
                })
            });
            if (!response.ok) {
                throw new Error('Failed to fetch monthly statistic data');
            }
            const data = await response.json();
            setStatisticData(data);
        } catch (err) {
            setError(err instanceof Error ? err.message : 'An unknown error occurred');
            setStatisticData([]);
        } finally {
            setLoading(false);
        }
    };

    const fetchDataTypeList = async () => {
        try {
            setLoading(true);
            const response = await fetchWithAuth('/api/Observations/datatypes', {
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            if (!response.ok) {
                throw new Error('Failed to fetch dat type');
            }
            const data = await response.json();
            setDataTypeList(data);
        } catch (err) {
            setError(err instanceof Error ? err.message : 'An unknown error occurred');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (baseUrl && user?.token && dataTypeList.length === 0) {
            fetchDataTypeList();
        }
    }, [baseUrl, user?.token]);

    const fetchStatisticTypeList = async (dataType: string) => {
        try {
            setLoading(true);
            const response = await fetchWithAuth(`/api/MonthlyStatistics/StatisticTypes?dataType=${dataType}`, {
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            if (!response.ok) {
                throw new Error('Failed to fetch statistic type');
            }
            const data = await response.json();
            setStatisticTypeList(data);
        } catch (err) {
            setError(err instanceof Error ? err.message : 'An unknown error occurred');
        } finally {
            setLoading(false);
        }
    };

    const fetchAreaList = async (dataType: string, setAreaList: (val: string[]) => void) => {
        try {
            setLoading(true);
            const response = await fetchWithAuth(`/api/Observations/areas?datatype=${dataType}`, {
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            if (!response.ok) {
                throw new Error('Failed to fetch area');
            }
            const data = await response.json();
            setAreaList(data);
        } catch (err) {
            setError(err instanceof Error ? err.message : 'An unknown error occurred');
        } finally {
            setLoading(false);
        }
    };

    const fetchStationList = async (dataType: string, area: string, setStationList: (val: string[]) => void) => {
        try {
            setLoading(true);
            const response = await fetchWithAuth(`/api/Observations/stations?datatype=${dataType}&area=${area}`, {
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            if (!response.ok) {
                throw new Error('Failed to fetch stations');
            }
            const data = await response.json();
            setStationList(data);
        } catch (err) {
            setError(err instanceof Error ? err.message : 'An unknown error occurred');
        } finally {
            setLoading(false);
        }
    };

    const fetchParameterList = async (stations: string[], setParameterList: (val: ParameterType[])=> void) => {
        try {
            setLoading(true);
            const response = await fetchWithAuth('/api/Observations/parameters', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(stations)
            });
            if (!response.ok) {
                throw new Error('Failed to fetch parameters');
            }
            const data = await response.json();
            setParameterList(data);
        } catch (err) {
            setError(err instanceof Error ? err.message : 'An unknown error occurred');
        } finally {
            setLoading(false);
        }
    };

    const exportMonthlyStatisticData = async (
        startDate: string,
        endDate: string,
        selectedDataType: string,
        selectedStation: string[],
        selectedParameters: string[],
        selectedStatisticType: number
    ) => {

        try {
            const response = await fetchWithAuth('/api/MonthlyStatistics/exportascsv', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    "startDate": startDate,
                    "endDate": endDate,
                    "dataType": selectedDataType,
                    "stations": selectedStation.join('|'),
                    "parameters": selectedParameters.join('|'),
                    "statisticType": selectedStatisticType
                })
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const blob = await response.blob();
            const contentDisposition = response.headers.get('content-disposition');
            let filename = 'Monthly_statistics.zip';
            if (contentDisposition) {
                const matches = contentDisposition.match(/filename\*?=(?:UTF-8''([^;]+)|\"([^\"]+)\")/);
                if (matches) {
                    filename = decodeURIComponent(matches[1] || matches[2]);
                }
            }
            filename = sanitizeFilename(filename);
            const downloadUrl = window.URL.createObjectURL(blob);
            createDownloadLink(downloadUrl, filename);
            window.URL.revokeObjectURL(downloadUrl);
            setSnackbarMessage('Monthly statistic data successfully downloaded');
            setSnackbarOpen(true);
            setVariant(VariantType.success);
        } catch (error) {
            console.error('Download failed:', error);
            setSnackbarMessage('Download monthly statistic data failed');
            setSnackbarOpen(true);
            setVariant(VariantType.error);
        }
    };

    return (
        <TimeseriesContext.Provider
            value={{
                loading,
                error,
                fetchObservationsData,
                exportObservationData,
                statisticTypeList,
                dataTypeList,
                fetchMonthlyStatisticData,
                exportMonthlyStatisticData,
                stationInfo,
                setStationInfo,
                isFullscreen,
                setIsFullscreen,
                fetchAreaList,
                fetchStationList,
                fetchParameterList,
                fetchStatisticTypeList,
                fetchDataTypeList
            }}
        >
            {children}
        </TimeseriesContext.Provider>
    );
};

export const useTimeseriesView = () => {
    const context = useContext(TimeseriesContext);
    if (context === undefined) {
        throw new Error('useTimeseriesView must be used within a TimeseriesViewProvider');
    }
    return context;
};
