import React from 'react';
import Box from '@mui/material/Box';
import ButtonWithIcon from '../../common/button/Button';
import { Person, AccountCircle } from '@mui/icons-material';
import Menu from '@mui/material/Menu';
import { useAuth } from '../../../context/auth-context/AuthContext';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, MenuItem, Stack, Tooltip, useMediaQuery } from '@mui/material';
import Text from '../../common/text/Text';
import {
    buttonStyle,
    userButtonStyle,
    menuSx,
    userInfoTextSx,
    userEmailTextSx,
    menuItemTextSx,
} from './Style';
import useUserSettings from './useUserSetting';

const UserProfile: React.FC = () => {

    const { user } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const userSettings = useUserSettings();
    const isSmallScreen = useMediaQuery('(max-width: 799px)');

    const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);

    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    return (
        <Box flexDirection={'row'} alignItems={'center'} flex={1} sx={{ textAlign: 'right' }}>
            {user === null ? (
                <ButtonWithIcon
                    variant='contained'
                    icon={<Person />}
                    onClick={() => navigate('/login')}
                    text='Log In'
                    sx={buttonStyle}
                />
            ) : (
                <Tooltip title="User settings">
                    <Button
                        onClick={handleOpenUserMenu}
                        variant="outlined"
                        endIcon={<AccountCircle sx={{ color: location.pathname === '/' ? 'white' : '#F65E27' }} />}
                        sx={userButtonStyle(location.pathname)}
                    >
                        {!isSmallScreen && user?.name}
                    </Button>
                </Tooltip>
            )}
            <Menu
                sx={menuSx}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
            >
                <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1} width={300} padding={2}>
                    <AccountCircle sx={{ color: '#F65E27' }} />
                    <Stack direction="column">
                        <Text variant='caption' sx={userInfoTextSx}>
                            {user?.name}
                        </Text>
                        <Text variant='caption' sx={userEmailTextSx}>
                            {user?.email}
                        </Text>
                    </Stack>
                </Stack>
                {userSettings.filter((setting: { isAuthorized: any; }) => setting.isAuthorized).map((setting) => (
                    <MenuItem
                        key={setting.title}
                        onClick={() => {
                            setting.onclick();
                            handleCloseUserMenu();
                        }}
                    >
                        {setting.icon ? <setting.icon sx={{ color: '#063342' }} /> : null}
                        <Text variant='caption' sx={menuItemTextSx}>
                            {setting.title}
                        </Text>
                    </MenuItem>
                ))}
            </Menu>
        </Box>
    );
};

export default UserProfile;
