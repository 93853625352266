import { useAuth } from '../context/auth-context/AuthContext';
import { useFetchWithAuth } from './useFetchWithAuth';

export type MenuBar = {
    id: number
    link: string
    title: string
}

interface UseFetchMenuBarReturn {
  fetchMenuBar: () => Promise<void>;
}

const useMenuBar = (): UseFetchMenuBarReturn => {
  
    const fetchWithAuth = useFetchWithAuth()
    const { setMenuBar} = useAuth()
  
  const fetchMenuBar = async () => {
    try {
      const response = await fetchWithAuth('/api/Map/menubar', {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (!response.ok) {
        throw new Error('Failed to fetch menu bar data');
      }
      const data = await response.json();
      setMenuBar(data);
    } catch (err) {
      console.log(err)
    }
  };

  return {
    fetchMenuBar,
  };
};

export default useMenuBar;
