import React, { useState } from 'react';
import { containerStyle } from './Style';
import {
    Box, SelectChangeEvent, Table, TableBody, TableCell, TableContainer,
    TableHead, TableRow, Paper, useMediaQuery, IconButton, Tooltip, Typography,
    TablePagination, Popover, TextField, Button
} from '@mui/material';
import { ArrowDropDown, ArrowDropUp, FilterList, Download } from '@mui/icons-material';
import { DataTypeSelector } from '../timeseries-content/Selectors';
import { useAuth } from '../../../context/auth-context/AuthContext';
import { useTimeseriesView } from '../../../context/timeseries-context/timeseriesContext';
import StationFilter from '../../feature/filter/StationFilter';
import { formatDate, formatDigitValue, SortConfig } from '../../../utils/Utils';
import { stationLabel } from './DataPoint';
import chartIcon from '../../../asset/Icon/Popup/chart.svg';
import mapIcon from '../../../asset/Icon/Popup/map.svg';
import { NavLink } from 'react-router-dom';
import { useDataPointView } from '../../../context/data-point-context/DataPointContext';
import { useMapView } from '../../../context/map-context/mapContext';
import { handleHighlightLayer } from '../../../utils/MapUtils';
import { dataType } from '../../../pages/map-view-page/MapViewLib';
import ButtonWithIcon from '../../common/button/Button';

const DataPointContent = () => {

    const { user } = useAuth();
    const { stationInfo, setStationInfo, dataTypeList } = useTimeseriesView();
    const { fetchStationById, setHighlightLayer } = useMapView();
    const { downLoadDataPoint, listStationsDetails, selectedDataType, setSelectedDataType, stationFilter } = useDataPointView();
    const isSmallScreen = useMediaQuery('(min-width: 999px)');

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [order, setOrder] = useState<'ascending' | 'descending'>('ascending');
    const [orderBy, setOrderBy] = useState<string>('stationId');
    const [sortConfig, setSortConfig] = useState<SortConfig>({ key: null, direction: 'ascending' });

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [filterLabel, setFilterLabel] = useState<string | null>(null)
    const [filterField, setFilterField] = useState<string | null>(null);
    const [filterValue, setFilterValue] = useState<string>('');

    const handleDataTypeChange = (event: SelectChangeEvent) => {
        const newDataType = event.target.value as string;
        setSelectedDataType(newDataType);
    };

    const userRoles = Array.isArray(user?.roles) ? user.roles : [user?.roles];

    const stationsWithParameters = listStationsDetails.flatMap((station: any) =>
        station.parameters
            .filter((parameter: any) => {
                if (parameter.name === 'Battery' && !(userRoles.includes('DHIAdmin') || userRoles.includes('SuperAdministrator'))) {
                    return false;
                }
                return true;
            })
            .map((parameter: any) => ({
                stationId: station.stationId,
                parameterName: parameter.displayName,
                longitude: station.longitude,
                latitude: station.latitude,
                firstUpdatedDateTime: parameter.firstUpdatedDateTime,
                lastUpdatedDateTime: parameter.lastUpdatedDateTime,
                latestValue: parameter.latestValue,
                unit: parameter.unit,
                isOnline: parameter.isOnline,
                isWithinThresholdLimits: parameter.isWithinThresholdLimits,
                dataType: station.dataType,
                station,
                parameter
            }))
    );

    const compareValues = (a: any, b: any, key: string, direction: 'ascending' | 'descending') => {
        let aValue = a[key];
        let bValue = b[key];

        if (typeof aValue === 'string') {
            aValue = aValue.toLowerCase();
            bValue = bValue.toLowerCase();
        }

        if (direction === 'ascending') {
            if (aValue < bValue) return -1;
            if (aValue > bValue) return 1;
        } else {
            if (aValue > bValue) return -1;
            if (aValue < bValue) return 1;
        }

        return 0;
    };

    const handleSort = (key: string) => {
        let newDirection: 'ascending' | 'descending' = 'ascending';

        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            newDirection = 'descending';
        }

        setSortConfig({ key, direction: newDirection });
        setOrder(newDirection);
        setOrderBy(key);
    };

    const sortedData = stationsWithParameters.filter((station: any) =>
        station.dataType.includes(selectedDataType) &&
        (
            station.stationId.toLowerCase().includes(stationFilter) ||
            station.parameterName.toLowerCase().includes(stationFilter) ||
            station.longitude?.toString().includes(stationFilter) ||
            station.latitude?.toString().includes(stationFilter) ||
            station.firstUpdatedDateTime?.toLowerCase().includes(stationFilter) ||
            station.lastUpdatedDateTime?.toLowerCase().includes(stationFilter) ||
            station.latestValue?.toString().includes(stationFilter)
        )
    );

    const filteredData = sortedData
        .sort((a: any, b: any) => a.stationId.localeCompare(b.stationId))
        .filter((data: any) => {
            const searchValue = filterValue.toLowerCase();
            if (!filterField) return true;
            const fieldValue = data[filterField];
            if (typeof fieldValue === 'string' || typeof fieldValue === 'number') {
                return fieldValue.toString().toLowerCase().includes(searchValue);
            }
            return false;
        });

    const paginatedData = filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).sort((a: any, b: any) => compareValues(a, b, orderBy, order));

    const rowStyle = { fontSize: isSmallScreen ? 12 : 11 }

    const handleClickTimeseriesButton = (data: any) => {
        handleHighlightLayer([data.station.stationId], setHighlightLayer, false);
        setStationInfo({
            ...stationInfo,
            selectedPeriod: data.station.dataType === dataType.WaterQuality ? 'Last 24 Hours' : 'Last 1 Year',
            selectedDataType: data.station.dataType,
            selectedStation: [data.station.stationId],
            selectedParameters: [data.parameter.name],
            selectedArea: data.station.area
        });
    };

    const handleClickMapViewButton = (data: any) => {
        fetchStationById(data.station.stationId);
        handleHighlightLayer([data.station.stationId], setHighlightLayer, false);
        setStationInfo({
            ...stationInfo,
            selectedDataType: data.station.dataType,
            selectedStation: [data.station.stationId],
            selectedParameters: [data.parameter.name],
            selectedArea: data.area
        });
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleFilterClick = (event: React.MouseEvent<HTMLElement>, label: any) => {
        setAnchorEl(event.currentTarget);
        setFilterLabel(label.label)
        setFilterField(label.value);
        setFilterValue('');
    };

    const handleFilterClose = () => {
        setAnchorEl(null);
    };

    const handleFilterApply = () => {
        setAnchorEl(null);
        setPage(0);
    };

    return (
        <Box sx={containerStyle}>
            <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" padding={1}>
                <Box position="relative" sx={{ width: '100%', textAlign: 'left' }}>
                    <DataTypeSelector
                        selectedDataType={selectedDataType}
                        handleDataTypeChange={handleDataTypeChange}
                        filteredDataTypeList={dataTypeList}
                    />
                </Box>
                <Box display="flex" flexDirection="row" justifyContent="flex-end" alignItems="center" gap={1}>
                    <ButtonWithIcon
                        variant='outlined'
                        icon={<Download />}
                        onClick={() => downLoadDataPoint(selectedDataType)}
                        text={'Download'}
                        sx={{
                            color: '#fff',
                            borderColor: '#f58e3f',
                            backgroundColor: '#f58e3f',
                            borderRadius: 2,
                            textTransform: 'capitalize',
                            width: 150,
                            fontSize: isSmallScreen ? '14px' : '11px',
                            ':hover': {
                                color: '#fff',
                                borderColor: '#f58e3f',
                                backgroundColor: '#f58e3f',
                                opacity: 0.8,
                            },
                        }}
                    />
                    <StationFilter />
                </Box>
            </Box>
            <Box sx={{ padding: 1 }}>
                <TableContainer component={Paper} sx={{ maxHeight: '65vh' }}>
                    <Table stickyHeader aria-label="sticky table" size='small'>
                        <TableHead>
                            <TableRow>
                                {stationLabel.map(item => (
                                    <TableCell
                                        key={item.id}
                                        align="left"
                                        sx={{ cursor: 'pointer' }}
                                        onClick={() => handleSort(item.value)}
                                    >
                                        <Typography sx={{
                                            fontWeight: 'bold',
                                            fontSize: isSmallScreen ? 14 : 12,
                                            display: 'inline-block'
                                        }}>
                                            {item.label}
                                        </Typography>
                                        {(orderBy === item.value) ? (
                                            sortConfig.direction === 'ascending' ? (
                                                <Tooltip title={`Sort ${sortConfig.direction}`}>
                                                    <IconButton
                                                        size="small"
                                                        aria-label="sorting data point"
                                                        aria-controls="sorting data point"
                                                        aria-haspopup="true"
                                                        color="inherit"
                                                    >
                                                        <ArrowDropDown sx={{ fontSize: 16, display: 'inline-block' }} />
                                                    </IconButton>
                                                </Tooltip>
                                            ) : (
                                                <Tooltip title={`Sort ${sortConfig.direction}`}>
                                                    <IconButton
                                                        size="small"
                                                        aria-label="sorting data point"
                                                        aria-controls="sorting data point"
                                                        aria-haspopup="true"
                                                        color="inherit"
                                                    >
                                                        <ArrowDropUp sx={{ fontSize: 16, display: 'inline-block' }} />
                                                    </IconButton>
                                                </Tooltip>
                                            )
                                        ) : null}
                                        {(orderBy === item.value) ? (
                                            <Tooltip title={`Filter by ${item.label}`}>
                                                <IconButton
                                                    size="small"
                                                    aria-label="filter data point"
                                                    aria-controls="filter data point"
                                                    aria-haspopup="true"
                                                    color="inherit"
                                                    onClick={(event) => handleFilterClick(event, item)}
                                                >
                                                    <FilterList sx={{ fontSize: 16, display: 'inline-block' }} />
                                                </IconButton>
                                            </Tooltip>
                                        ) : null}
                                    </TableCell>
                                ))}
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {paginatedData.length > 0 ? (
                                paginatedData.map((data: any, index: number) => (
                                    <TableRow key={`${data.stationId}-${data.parameterName}-${index}`}>
                                        <TableCell component="th" scope="row" sx={rowStyle}>{data.stationId}</TableCell>
                                        <TableCell align="left" sx={rowStyle}>{data.parameterName}</TableCell>
                                        <TableCell align="left" sx={rowStyle}>{data.longitude}</TableCell>
                                        <TableCell align="left" sx={rowStyle}>{data.latitude}</TableCell>
                                        <TableCell align="left" sx={rowStyle}>{formatDate(data.firstUpdatedDateTime, true)}</TableCell>
                                        <TableCell align="left" sx={rowStyle}>{formatDate(data.lastUpdatedDateTime, true)}</TableCell>
                                        <TableCell
                                            align="left"
                                            sx={{
                                                color: data.isOnline
                                                    ? (data.isWithinThresholdLimits ? 'green' : 'red')
                                                    : (data.isWithinThresholdLimits ? 'grey' : 'red'),
                                                ...rowStyle
                                            }}
                                        >
                                            {
                                                data.latestValue !== null ? `${formatDigitValue(data.latestValue, selectedDataType)} ${data.unit}` : ' - '
                                            }
                                        </TableCell>
                                        <TableCell align="left">
                                            <Box sx={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                justifyContent: 'flex-start',
                                                alignItems: 'center',
                                                flexWrap: 'wrap'
                                            }}>
                                                <Tooltip title="View on Map">
                                                    <IconButton
                                                        component={NavLink}
                                                        to="/map-view"
                                                        onClick={() => handleClickMapViewButton(data)}
                                                    >
                                                        <img src={mapIcon} style={{ width: '10px' }} alt="timeseries-icon" />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title="View Timeseries">
                                                    <IconButton
                                                        component={NavLink}
                                                        to="/timeseries"
                                                        onClick={() => handleClickTimeseriesButton(data)}
                                                    >
                                                        <img src={chartIcon} style={{ width: '10px' }} alt="timeseries-icon" />
                                                    </IconButton>
                                                </Tooltip>
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                ))
                            ) : (
                                <TableRow>
                                    <TableCell colSpan={stationLabel.length + 1} align="center">
                                        <Typography>No data available</Typography>
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25, 50, 100]}
                    component="div"
                    count={filteredData.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Box>

            <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleFilterClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <Box p={1}>
                    <TextField
                        label={`Enter ${filterLabel}`}
                        variant="outlined"
                        fullWidth
                        value={filterValue}
                        onChange={(e) => setFilterValue(e.target.value)}
                        sx={{ marginTop: 1, fontSize: 11 }}
                    />
                    <Box display="flex" justifyContent="flex-end" mt={1}>
                        <Button variant="contained" onClick={handleFilterApply}>Apply</Button>
                    </Box>
                </Box>
            </Popover>
        </Box>
    );
}

export default DataPointContent;
