import React, { Fragment, useEffect, useState } from 'react';
import { Box, FormControl, Select, MenuItem, SelectChangeEvent, OutlinedInput, Checkbox, TextField, useMediaQuery } from '@mui/material';
import Text from '../../common/text/Text';
import { periodList } from '../../../utils/TimeseriesUtils';
import { useLocation } from 'react-router-dom';
import { useAuth } from '../../../context/auth-context/AuthContext';
import { ParameterType } from '../../../pages/time-series-page/TimeseriesLib';

export const PeriodSelector = ({ panelType, selectedPeriod, handlePeriodChange, startDate, setStartDate, endDate, setEndDate, handleApplyClick }: any) => {

    const isSmallScreen = useMediaQuery('(max-width: 1000px)');

    const getPeriodList = () => {
        if (panelType === 'Monthly') {
            return periodList.filter(item => item !== 'Last 1 Week' && item !== 'Last 24 Hours');
        }
        return periodList;
    };

    return (
        <Box display="flex" flexDirection="row" justifyContent="flex-start" alignItems="flex-start" gap={1} flexWrap={'wrap'}>
            <Box display="flex" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
                <Text variant="caption" sx={{ color: '#0B4566', fontSize: isSmallScreen ? 12 : 14 }}>Select Period</Text>
                <Box sx={{ mt: 1, mb: 1, display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
                    <FormControl sx={{ width: isSmallScreen ? 150 : 200, textAlign: 'left' }}>
                        <Select
                            value={selectedPeriod}
                            displayEmpty
                            onChange={handlePeriodChange}
                            inputProps={{ 'aria-label': 'Without label' }}
                            sx={{
                                color: '#09334B',
                                fontSize: isSmallScreen ? 11 : 12,
                                fontWeight: 'bold',
                                height: isSmallScreen ? 30 : 40,
                            }}
                        >
                            {getPeriodList().map(name => (
                                <MenuItem
                                    value={name}
                                    key={name}
                                    sx={{
                                        color: '#09334B',
                                        fontSize: isSmallScreen ? 11 : 12,
                                        fontWeight: 'bold'
                                    }}>{name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>
            </Box>
            {selectedPeriod === 'Custom' && (
                <Fragment>
                    <Box display="flex" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
                        <Text variant="caption" sx={{ color: '#0B4566', fontSize: isSmallScreen ? 12 : 14 }}>Start date</Text>
                        <Box sx={{ mt: 1, mb: 1, display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
                            <FormControl sx={{ width: isSmallScreen ? 150 : 200, textAlign: 'left' }}>
                                <TextField
                                    type="date"
                                    value={startDate.substring(0, 10)}
                                    onChange={(e) => setStartDate(e.target.value + 'T00:00:00')}
                                    InputLabelProps={{ shrink: true }}
                                    InputProps={{
                                        style: { color: '#09334B', fontSize: isSmallScreen ? 11 : 12, fontWeight: 'bold', height: isSmallScreen ? 30 : 40 },
                                    }}
                                />
                            </FormControl>
                        </Box>
                    </Box>
                    <Box display="flex" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
                        <Text variant="caption" sx={{ color: '#0B4566', fontSize: isSmallScreen ? 12 : 14 }}>End date</Text>
                        <Box sx={{ mt: 1, mb: 1, display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
                            <FormControl sx={{ width: isSmallScreen ? 150 : 200, textAlign: 'left' }}>
                                <TextField
                                    type="date"
                                    value={endDate.substring(0, 10)}
                                    onChange={(e) => setEndDate(e.target.value + 'T23:59:59')}
                                    InputLabelProps={{ shrink: true }}
                                    InputProps={{
                                        style: { color: '#09334B', fontSize: isSmallScreen ? 11 : 12, fontWeight: 'bold', height: isSmallScreen ? 30 : 40 },
                                    }}
                                />
                            </FormControl>
                        </Box>
                    </Box>
                </Fragment>
            )}
        </Box>
    );
};

export const DataTypeSelector = ({ selectedDataType, handleDataTypeChange, filteredDataTypeList }: any) => {
    const isSmallScreen = useMediaQuery('(max-width: 1000px)');
    const location = useLocation()

    return (
        <Box display="flex" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
            {
                location.pathname === '/timeseries' && <Text variant="caption" sx={{ color: '#0B4566', fontSize: isSmallScreen ? 12 : 14 }}>Data Type</Text>
            }
            <Box sx={{ mt: 1, mb: 1, display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: "center" }}>
                <FormControl sx={{ width: isSmallScreen ? 150 : 200, textAlign: 'left' }}>
                    <Select
                        value={selectedDataType}
                        displayEmpty
                        onChange={handleDataTypeChange}
                        inputProps={{ 'aria-label': 'Without label' }}
                        sx={{
                            color: '#09334B',
                            fontSize: isSmallScreen ? 11 : 12,
                            fontWeight: 'bold',
                            height: isSmallScreen ? 30 : 40,
                        }}
                    >
                        {filteredDataTypeList.map((type: any) => (
                            <MenuItem
                                value={type.name}
                                key={type.description}
                                sx={{
                                    color: '#09334B',
                                    fontSize: isSmallScreen ? 11 : 12,
                                    fontWeight: 'bold'
                                }}>{type.description}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Box>
        </Box>
    )
};

export const AreaSelector = ({ selectedArea, handleAreaChange, areaList }: any) => {
    const isSmallScreen = useMediaQuery('(max-width: 1000px)');
    return (
        <Box display="flex" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
            <Text variant="caption" sx={{ color: '#0B4566', fontSize: isSmallScreen ? 12 : 14 }}>Area</Text>
            <Box sx={{ mt: 1, mb: 1, display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: "center" }}>
                <FormControl sx={{ width: isSmallScreen ? 150 : 200, textAlign: 'left' }}>
                    <Select
                        value={selectedArea}
                        displayEmpty
                        onChange={handleAreaChange}
                        inputProps={{ 'aria-label': 'Without label' }}
                        sx={{
                            color: '#09334B',
                            fontSize: isSmallScreen ? 11 : 12,
                            fontWeight: 'bold',
                            height: isSmallScreen ? 30 : 40,
                        }}
                    >
                        {areaList?.map((area: any) => (
                            <MenuItem
                                value={area}
                                key={area}
                                sx={{
                                    color: '#09334B',
                                    fontSize: isSmallScreen ? 11 : 12,
                                    fontWeight: 'bold'
                                }}>{area}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Box>
        </Box>
    )
};

export const StationSelector = ({ selectedStation, handleStationChange, stationList }: any) => {
    const isSmallScreen = useMediaQuery('(max-width: 1000px)');
    const [selectAll, setSelectAll] = useState(false);

    const handleSelectAll = () => {
        const checked = !selectAll;
        setSelectAll(checked);
        if (checked) {
            handleStationChange({
                target: {
                    value: stationList
                }
            });
        } else {
            handleStationChange({
                target: {
                    value: []
                }
            });
        }
    };

    useEffect(() => {
        if (selectedStation.length === stationList.length && stationList.length > 0) {
            setSelectAll(true);
        } else {
            setSelectAll(false);
        }
    }, [selectedStation, stationList]);

    return (
        <Box display="flex" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
            <Text variant="caption" sx={{ color: '#0B4566', fontSize: isSmallScreen ? 12 : 14 }}>Station</Text>
            <Box sx={{ mt: 1, mb: 1, display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: "center" }}>
                <FormControl sx={{ width: isSmallScreen ? 150 : 200, textAlign: 'left' }}>
                    <Select
                        multiple
                        value={selectedStation}
                        onChange={handleStationChange}
                        displayEmpty
                        input={<OutlinedInput id="select-multiple-stations" />}
                        renderValue={(selected) => selected.length !== 0 && selected !== null && selected.join(', ')}
                        sx={{
                            color: '#09334B',
                            fontSize: isSmallScreen ? 11 : 12,
                            fontWeight: 'bold',
                            height: isSmallScreen ? 30 : 40
                        }}
                    >
                        <MenuItem sx={{ color: '#09334B', fontSize: isSmallScreen ? 11 : 12, fontWeight: 'bold' }}>
                            <Checkbox
                                checked={selectAll}
                                onChange={handleSelectAll}
                            />
                            Select All
                        </MenuItem>

                        {stationList
                            .sort((a: string, b: string) => a?.localeCompare(b))
                            .map((name: any) => (
                                <MenuItem key={name} value={name} sx={{ color: '#09334B', fontSize: isSmallScreen ? 11 : 12, fontWeight: 'bold' }}>
                                    <Checkbox checked={selectedStation.indexOf(name) > -1} />
                                    {name}
                                </MenuItem>
                            ))}
                    </Select>
                </FormControl>
            </Box>
        </Box>
    );
};

export const ParameterSelector = ({ panelType, selectedParameters, handleParameterChange, parameterList }: any) => {
    const isSmallScreen = useMediaQuery('(max-width: 1000px)');
    const { user } = useAuth();
    const userRoles = Array.isArray(user?.roles) ? user.roles : [user?.roles];
    const isAdmin = userRoles.includes('DHIAdmin') || userRoles.includes('SuperAdministrator');

    const filteredParameterList = parameterList.filter((param: ParameterType) => {
        if (param.name === 'Battery' && !isAdmin) {
            return false;
        }
        if (param.name === 'percentage_monthly') {
            return panelType === 'Observation' && isAdmin;
        }
        return true;
    });

    const selectedParameterNames = selectedParameters
        .map((id: string) => parameterList.find((param: ParameterType) => param.id === id)?.name)
        .filter(Boolean)
        .join(', ');

    return (
        <Box display="flex" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
            <Text variant="caption" sx={{ color: '#0B4566', fontSize: isSmallScreen ? 12 : 14 }}>Parameters</Text>
            <Box sx={{ mt: 1, mb: 1, display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: "center" }}>
                <FormControl sx={{ width: isSmallScreen ? 150 : 200, textAlign: 'left' }}>
                    <Select
                        multiple
                        value={selectedParameters}
                        onChange={handleParameterChange}
                        displayEmpty
                        input={<OutlinedInput id="select-multiple-parameters" />}
                        renderValue={() => selectedParameterNames}
                        sx={{
                            color: '#09334B',
                            fontSize: isSmallScreen ? 11 : 12,
                            fontWeight: 'bold',
                            height: isSmallScreen ? 30 : 40,
                        }}
                    >
                        {filteredParameterList
                            .sort((a: ParameterType, b: ParameterType) => a.name?.localeCompare(b.name))
                            .map((param: ParameterType) => (
                                <MenuItem key={param.id} value={param.id} sx={{ color: '#09334B', fontSize: isSmallScreen ? 11 : 12, fontWeight: 'bold' }}>
                                    <Checkbox checked={selectedParameters.indexOf(param.id) > -1} />
                                    {param.name}
                                </MenuItem>
                            ))}
                    </Select>
                </FormControl>
            </Box>
        </Box>
    );
};

export const StatisticTypeSelector = ({ selectedStatisticType, setSelectedStatisticType, statisticTypeList }: any) => {
    const isSmallScreen = useMediaQuery('(max-width: 1000px)');
    return (
        <Box display="flex" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
            <Text variant="caption" sx={{ color: '#0B4566', fontSize: isSmallScreen ? 12 : 14 }}>Statistic</Text>
            <Box sx={{ mt: 1, mb: 1, display: 'flex', flexDirection: "row", justifyContent: "flex-start", alignItems: "center" }}>
                <FormControl sx={{ width: isSmallScreen ? 150 : 200, textAlign: 'left' }}>
                    <Select
                        value={selectedStatisticType.toString()}
                        displayEmpty
                        onChange={(event: SelectChangeEvent) => setSelectedStatisticType(Number(event.target.value))}
                        inputProps={{ 'aria-label': 'Without label' }}
                        sx={{
                            color: '#09334B',
                            fontSize: isSmallScreen ? 11 : 12,
                            fontWeight: 'bold',
                            height: isSmallScreen ? 30 : 40,
                        }}
                    >
                        {statisticTypeList?.filter((item: any) => (item.name !== 'DataCoverage') && (item.name !== 'DataCount')).map(({ value, label }: any) => (
                            <MenuItem
                                value={value.toString()}
                                key={value}
                                sx={{
                                    color: '#09334B',
                                    fontSize: isSmallScreen ? 11 : 12,
                                    fontWeight: 'bold'
                                }}>{label}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Box>
        </Box>
    )
};
