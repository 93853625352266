import { Box, useMediaQuery, useTheme } from '@mui/material';
import React, { Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import sfalogotext from '../../../asset/Logo/sfa_logo_text_monowhite.png';
import dhilogo from '../../../asset/Logo/DHI_Logo_White.png';
import Text from '../../common/text/Text';
import { getYear } from '../../../utils/Utils';
import { useAuth } from '../../../context/auth-context/AuthContext';

const Footer: React.FC = () => {

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const isMediumScreen = useMediaQuery(theme.breakpoints.between('sm', 'md'));

    const { user, menuBar } = useAuth();

    const year = getYear();

    return (
        <Box sx={{ backgroundColor: '#282828', width: '100%', boxSizing: 'border-box', borderTop: '3px solid #F88E3A' }}>
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: isSmallScreen ? 'column' : 'row',
                    justifyContent: 'space-between',
                    alignItems: isSmallScreen ? 'flex-start' : 'center',
                    padding: '20px',
                    boxSizing: 'border-box'
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flex: 3,
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'flex-start',
                        marginBottom: isSmallScreen ? '20px' : '0'
                    }}
                >
                    {user && (
                        <Fragment>
                            <Text
                                variant='body1'
                                sx={{
                                    width: '100%',
                                    textAlign: 'left',
                                    fontWeight: 700,
                                    color: '#F65E27',
                                    marginBottom: '10px'
                                }}
                            >
                                Quick link
                            </Text>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: isSmallScreen || isMediumScreen ? 'column' : 'row',
                                    justifyContent: 'flex-start',
                                    alignItems: 'flex-start'
                                }}
                            >
                                {menuBar?.map(res => (
                                    <NavLink
                                        key={res.id}
                                        to={res.link}
                                        style={({ isActive }) => ({
                                            display: 'block',
                                            textDecoration: 'none',
                                            color: isActive ? '#d47606' : 'white',
                                            marginRight: isSmallScreen ? 0 : 20,
                                            marginBottom: isSmallScreen ? 10 : 0,
                                            fontSize: isSmallScreen ? 11 : isMediumScreen ? 12 : 14,
                                        })}
                                    >
                                        {res.title}
                                    </NavLink>
                                ))}
                            </Box>
                        </Fragment>
                    )}
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                        flex: 1,
                        marginBottom: isSmallScreen ? '20px' : '0',
                        gap: 2
                    }}
                >
                    <img
                        src={sfalogotext}
                        alt='sfa-logo'
                        style={{
                            display: 'block',
                            height: 60
                        }}
                    />
                    <img
                        src={dhilogo}
                        alt='sfa-logo'
                        style={{
                            display: 'block',
                            height: 60
                        }}
                    />
                </Box>
            </Box>
            <Box
                sx={{
                    fontSize: 12,
                    color: '#808080',
                    backgroundColor: '#212121',
                    padding: '20px',
                    textAlign: isSmallScreen ? 'center' : 'left'
                }}
            >
                copyright {year}. all right reserved
            </Box>
        </Box>
    )
}

export default Footer;
