import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import { useAuth } from '../../../context/auth-context/AuthContext';
import useMediaQuery from '@mui/material/useMediaQuery';
import { mobileMenuStyle, mobileNavLinkStyle, activeMobileNavLinkStyle, hoverMobileNavLinkStyle } from './Style';

const MobileMenu: React.FC = () => {

    const location = useLocation();
    const { menuBar, setIsMobileMenuActive } = useAuth();
    const isTabletOrDesktop = useMediaQuery('(min-width: 1150px)');
    const [hoveredIndex, setHoveredIndex] = React.useState<number | null>(null);

    return (
        <Box sx={() => mobileMenuStyle(isTabletOrDesktop, location)}>
            {menuBar?.map((res, index) => (
                <NavLink
                    key={res.id}
                    to={res.link}
                    onClick={() => setIsMobileMenuActive(false)}
                    onMouseEnter={() => setHoveredIndex(index)}
                    onMouseLeave={() => setHoveredIndex(null)}
                    style={{
                        ...mobileNavLinkStyle,
                        ...(location.pathname === res.link ? activeMobileNavLinkStyle : {}),
                        ...(hoveredIndex === index ? hoverMobileNavLinkStyle : {}),
                    }}
                >
                    {res.title}
                </NavLink>
            ))}
        </Box>
    );
};

export default MobileMenu;
