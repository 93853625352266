import React, { useEffect } from 'react';
import { Box, useMediaQuery } from '@mui/material';
import Split from 'react-split';
import DeckGLMap from '../../components/layout/map/ReactMapGL';
import TimeseriesMenu from '../../components/layout/timeseries-content/TimeseriesMenu';
import { useAuth } from '../../context/auth-context/AuthContext';
import { useTimeseriesView } from '../../context/timeseries-context/timeseriesContext';
import { useMapView } from '../../context/map-context/mapContext';
import Notification from '../../components/feature/notification/Notification';

const containerStyles = (isSmallScreen: boolean) => ({
  position: 'relative',
  width: '100%',
  height: 'calc(100vh - 65px)',
  zIndex: 0,
  top: 0,
  left: 0,
  display: 'flex',
  flexDirection: isSmallScreen ? 'row' : 'column',
  overflowY: 'hidden',
});

const splitStyles = {
  display: 'flex',
  width: '100%',
  height: '100%',
};

const boxStyles = {
  width: '100%',
  height: '100%',
  overflow: 'hidden',
};

const TimeSeriesPage = () => {

  const { snackbarOpen, setSnackbarOpen, snackbarMessage, variant } = useAuth();
  const { isFullscreen, setIsFullscreen } = useTimeseriesView();
  const isSmallScreen = useMediaQuery('(min-width: 1000px)');
  const { mapRef } = useMapView();

  const alertSeverity: 'success' | 'info' | 'warning' | 'error' = (['success', 'info', 'warning', 'error'].includes(variant) ? variant : 'info') as 'success' | 'info' | 'warning' | 'error';

  useEffect(() => {
    if (!isSmallScreen) {
      setIsFullscreen(true);
    }
  }, [isSmallScreen, setIsFullscreen]);

  const handleResize = () => {
    if (mapRef.current) {
      mapRef.current.getMap().resize();
    }
  };

  const renderSplitView = () => (
    <Split
      sizes={isFullscreen ? [100, 0] : [65, 35]}
      minSize={isFullscreen ? [0, 0] : [100, 100]}
      style={splitStyles}
      expandToMin={true}
      gutterSize={5}
      gutterAlign="center"
      dragInterval={1}
      direction="horizontal"
      cursor="col-resize"
      onDragEnd={handleResize}
    >
      <Box sx={boxStyles}>
        <TimeseriesMenu />
      </Box>
      <Box sx={{ ...boxStyles, display: isFullscreen ? 'none' : 'block' }}>
        <DeckGLMap />
      </Box>
    </Split>
  );

  const renderColumnView = () => (
    <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%' }}>
      <Box sx={{ ...boxStyles, height: isFullscreen ? '100%' : '80%' }}>
        <TimeseriesMenu />
      </Box>
      <Box sx={{ ...boxStyles, height: isFullscreen ? '0%' : '20%', display: isFullscreen ? 'none' : 'block' }}>
        <DeckGLMap />
      </Box>
    </Box>
  );

  return (
    <Box sx={containerStyles(isSmallScreen)}>
      {isSmallScreen ? renderSplitView() : renderColumnView()}
      <Notification
        open={snackbarOpen}
        message={snackbarMessage}
        severity={alertSeverity}
        onClose={() => setSnackbarOpen(false)}
      />
    </Box>
  );
};

export default TimeSeriesPage;
