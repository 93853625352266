// src/context/UploadViewContext.tsx
import React, { createContext, useContext, ReactNode, useState } from 'react';
import { useAuth, VariantType } from '../auth-context/AuthContext';
import { createDownloadLink, sanitizeFilename } from '../../utils/Utils';
import { useFetchWithAuth } from '../../hooks/useFetchWithAuth';

interface UploadViewContextType {
    loading: boolean;
    downloadTemplateSampleInfo: () => void;
    uploadSampleInfo: (file: any) => void;
    uploadAdditionalSampleInfo: (additionalFiles: any) => void;
    downloadTemplateNutrientSurveyResult: (nutrientSurveyType: number) => void;
    uploadNutrientSurvey: (file: any, nutrientSurveyType: number) => void;
    uploadAdditionalNutrientSurvey: (files: any) => void;
    downloadTemplatePlanktonSurveyResult: () => void;
    uploadPlanktonSurvey: (file: any) => void;
    uploadAdditionalPlanktonSurvey: (files: any) => void;
    downloadTemplateSedimentQualityResult: () => void;
    uploadSedimentQualityResult: (file: any) => void;
    uploadAdditionalSedimentQualityResult: (files: any) => void;
}

const UploadContext = createContext<UploadViewContextType | undefined>(undefined);

export const UploadViewProvider = ({ children }: { children: ReactNode }) => {

    const { setSnackbarOpen, setSnackbarMessage, setVariant } = useAuth();
    const fetchWithAuth = useFetchWithAuth()
    const [sampleInfoId, setSampleInfoId] = useState<any>(null);
    const [nutrientSurveyId, setNutrientSurveyId] = useState<any>(null);
    const [planktonSurveyId, setPlanktonSurveyId] = useState<any>(null)
    const [loading, setLoading] = useState<boolean>(false)

    // sample info
    const downloadTemplateSampleInfo = async () => {
        try {
            const response = await fetchWithAuth('/api/SampleInfo/DownloadTemplate', {
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            if (!response.ok) {
                throw new Error('Failed to download template');
            }
            const blob = await response.blob();
            const contentDisposition = response.headers.get('content-disposition');
            let filename = 'SampleInfoTemplate.zip';
            if (contentDisposition) {
                const matches = contentDisposition.match(/filename\*?=(?:UTF-8''([^;]+)|\"([^\"]+)\")/);
                if (matches) {
                    filename = decodeURIComponent(matches[1] || matches[2]);
                }
            }
            filename = sanitizeFilename(filename);
            const downloadUrl = window.URL.createObjectURL(blob);
            createDownloadLink(downloadUrl, filename);
            window.URL.revokeObjectURL(downloadUrl);
        } catch (error) {
            setSnackbarMessage(error as string);
            setSnackbarOpen(true);
            setVariant(VariantType.error);
        }
    };

    const uploadSampleInfo = async (file: any) => {
        const formData = new FormData();
        formData.append('file', file);
        setLoading(true)
        try {
            const response = await fetchWithAuth('/api/SampleInfo/Upload', {
                method: 'POST',
                body: formData,
            });
            if (!response.ok) {
                let errorDetail = 'Failed to upload document';
                try {
                    const errorResponse = await response.json();
                    errorDetail = errorResponse.detail || errorDetail;
                } catch (jsonError) {
                    errorDetail = 'Failed to upload document'
                }
                throw new Error(errorDetail);
            }
            const uploadId = await response.text();
            setSampleInfoId(uploadId);
            setSnackbarMessage('Document successfully uploaded');
            setVariant(VariantType.success);
        } catch (error) {
            setSnackbarMessage(`${error}`);
            setVariant(VariantType.error);
            throw error;
        } finally {
            setSnackbarOpen(true);
            setLoading(false);
        }
    };

    const uploadAdditionalSampleInfo = async (additionalFiles: any) => {
        const formData = new FormData();
        additionalFiles.forEach((file: any) => formData.append('files', file));
        const cleanUploadId = sampleInfoId.trim().replace(/"/g, '');
        setLoading(true)
        try {
            const response = await fetchWithAuth(`/api/SampleInfo/AdditionalFiles?mainUploadId=${cleanUploadId}`, {
                method: 'POST',
                body: formData,
            });
            if (!response.ok) {
                let errorDetail = 'Failed to upload document';
                try {
                    const errorResponse = await response.json();
                    errorDetail = errorResponse.detail || errorDetail;
                } catch (jsonError) {
                    errorDetail = 'Failed to upload document'
                }
                throw new Error(errorDetail);
            }
            setSnackbarMessage('Document successfully uploaded');
            setVariant(VariantType.success);
        } catch (error) {
            setSnackbarMessage(`${error}`);
            setVariant(VariantType.error);
            throw error;
        } finally {
            setSnackbarOpen(true);
            setLoading(false);
        }
    };

    // nutrient survey
    const downloadTemplateNutrientSurveyResult = async (nutrientSurveyType: number) => {
        try {
            const response = await fetchWithAuth(`/api/NutrientSurveyResult/DownloadTemplate?nutrientSurveyType=${nutrientSurveyType}`, {
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            if (!response.ok) {
                throw new Error('Failed to download template');
            }
            const blob = await response.blob();
            const contentDisposition = response.headers.get('content-disposition');
            let filename = 'NutrientSurveyResultTemplate.xlsx';
            if (contentDisposition) {
                const matches = contentDisposition.match(/filename\*?=(?:UTF-8''([^;]+)|\"([^\"]+)\")/);
                if (matches != null) {
                    filename = decodeURIComponent(matches[1] || matches[2]);
                }
            }
            filename = sanitizeFilename(filename);
            const downloadUrl = window.URL.createObjectURL(blob);
            createDownloadLink(downloadUrl, filename);
            window.URL.revokeObjectURL(downloadUrl);
        } catch (error) {
            console.error('Download failed:', error);
            setSnackbarMessage('Failed to download template. Please try again.');
            setSnackbarOpen(true);
            setVariant(VariantType.error);
        }
    };

    const uploadNutrientSurvey = async (file: any, nutrientSurveyType: number) => {
        const formData = new FormData();
        formData.append('file', file);
        setLoading(true)
        try {
            const response = await fetchWithAuth(`/api/NutrientSurveyResult/Upload?nutrientSurveyType=${nutrientSurveyType}`, {
                method: 'POST',
                body: formData,
            });
            if (!response.ok) {
                let errorDetail = 'Failed to upload document';
                try {
                    const errorResponse = await response.json();
                    errorDetail = errorResponse.detail || errorDetail;
                } catch (jsonError) {
                    errorDetail = 'Failed to upload document'
                }
                throw new Error(errorDetail);
            }
            const uploadId = await response.text();
            setNutrientSurveyId(uploadId);
            setSnackbarMessage('Document successfully uploaded');
            setSnackbarOpen(true);
            setVariant(VariantType.success);
        } catch (error) {
            setSnackbarMessage(`${error}`);
            setVariant(VariantType.error);
            throw error;
        } finally {
            setSnackbarOpen(true);
            setLoading(false);
        }
    };

    const uploadAdditionalNutrientSurvey = async (additionalFiles: any) => {
        const formData = new FormData();
        additionalFiles.forEach((file: any) => formData.append('files', file));
        const cleanUploadId = nutrientSurveyId.trim().replace(/"/g, '');
        setLoading(true);
        try {
            const response = await fetchWithAuth(`/api/NutrientSurveyResult/AdditionalFiles?mainUploadId=${cleanUploadId}`, {
                method: 'POST',
                body: formData,
            });

            if (!response.ok) {
                let errorDetail = 'Failed to upload document';
                try {
                    const errorResponse = await response.json();
                    errorDetail = errorResponse.detail || errorDetail;
                } catch (jsonError) {
                    errorDetail = 'Failed to upload document'
                }
                throw new Error(errorDetail);
            }
            setSnackbarMessage('Document successfully uploaded');
            setVariant(VariantType.success);
        } catch (error) {
            setSnackbarMessage(`${error}`);
            setVariant(VariantType.error);
            throw error;
        } finally {
            setSnackbarOpen(true);
            setLoading(false);
        }
    };

    // plankton survey
    const downloadTemplatePlanktonSurveyResult = async () => {
        try {
            const response = await fetchWithAuth('/api/PlanktonSurveyResult/DownloadTemplate', {
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            if (!response.ok) {
                throw new Error('Failed to download template');
            }
            const blob = await response.blob();
            const contentDisposition = response.headers.get('content-disposition');
            let filename = 'PlanktonSurveyResultTemplate.zip';
            if (contentDisposition) {
                const matches = contentDisposition.match(/filename\*?=(?:UTF-8''([^;]+)|\"([^\"]+)\")/);
                if (matches != null) {
                    filename = decodeURIComponent(matches[1] || matches[2]);
                }
            }
            filename = sanitizeFilename(filename);
            const downloadUrl = window.URL.createObjectURL(blob);
            createDownloadLink(downloadUrl, filename);
            window.URL.revokeObjectURL(downloadUrl);
        } catch (error) {
            console.error('Download failed:', error);
            setSnackbarMessage('Failed to download template. Please try again.');
            setSnackbarOpen(true);
            setVariant(VariantType.error);
        }
    };

    const uploadPlanktonSurvey = async (file: any) => {
        const formData = new FormData();
        formData.append('file', file);
        setLoading(true)
        try {
            const response = await fetchWithAuth('/api/PlanktonSurveyResult/Upload', {
                method: 'POST',
                body: formData,
            });
            if (!response.ok) {
                let errorDetail = 'Failed to upload document';
                try {
                    const errorResponse = await response.json();
                    errorDetail = errorResponse.detail || errorDetail;
                } catch (jsonError) {
                    errorDetail = 'Failed to upload document'
                }
                throw new Error(errorDetail);
            }
            const uploadId = await response.text();
            setPlanktonSurveyId(uploadId);
            setSnackbarMessage('Document successfully uploaded');
            setSnackbarOpen(true);
            setVariant(VariantType.success);
        } catch (error) {
            setSnackbarMessage(`${error}`);
            setVariant(VariantType.error);
            throw error;
        } finally {
            setSnackbarOpen(true);
            setLoading(false);
        }
    };

    const uploadAdditionalPlanktonSurvey = async (additionalFiles: any) => {
        const formData = new FormData();
        additionalFiles.forEach((file: any) => formData.append('files', file));
        const cleanUploadId = planktonSurveyId.trim().replace(/"/g, '');
        setLoading(true);
        try {
            const response = await fetchWithAuth(`/api/PlanktonSurveyResult/AdditionalFiles?mainUploadId=${cleanUploadId}`, {
                method: 'POST',
                body: formData,
            });
            if (!response.ok) {
                let errorDetail = 'Failed to upload document';
                try {
                    const errorResponse = await response.json();
                    errorDetail = errorResponse.detail || errorDetail;
                } catch (jsonError) {
                    errorDetail = 'Failed to upload document'
                }
                throw new Error(errorDetail);
            }
            setSnackbarMessage('Document successfully uploaded');
            setVariant(VariantType.success);
        } catch (error) {
            setSnackbarMessage(`${error}`);
            setVariant(VariantType.error);
            throw error;
        } finally {
            setSnackbarOpen(true);
            setLoading(false);
        }
    };

    // sediment quality survey
    const downloadTemplateSedimentQualityResult = async () => {
        try {
            const response = await fetchWithAuth('/api/SedimentQualitySurveyResult/DownloadTemplate', {
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            if (!response.ok) {
                throw new Error('Failed to download template');
            }
            const blob = await response.blob();
            const contentDisposition = response.headers.get('content-disposition');
            let filename = 'SedimentQualitySurveyResultTemplate.xlsx';
            if (contentDisposition) {
                const matches = contentDisposition.match(/filename\*?=(?:UTF-8''([^;]+)|\"([^\"]+)\")/);
                if (matches != null) {
                    filename = decodeURIComponent(matches[1] || matches[2]);
                }
            }
            filename = sanitizeFilename(filename);
            const downloadUrl = window.URL.createObjectURL(blob);
            createDownloadLink(downloadUrl, filename);
            window.URL.revokeObjectURL(downloadUrl);
        } catch (error) {
            console.error('Download failed:', error);
            setSnackbarMessage('Failed to download template. Please try again.');
            setSnackbarOpen(true);
            setVariant(VariantType.error);
        }
    };

    const uploadSedimentQualityResult = async (file: any) => {
        const formData = new FormData();
        formData.append('file', file);
        setLoading(true)
        try {
            const response = await fetchWithAuth('/api/SedimentQualitySurveyResult/Upload', {
                method: 'POST',
                body: formData,
            });
            if (!response.ok) {
                let errorDetail = 'Failed to upload document';
                try {
                    const errorResponse = await response.json();
                    errorDetail = errorResponse.detail || errorDetail;
                } catch (jsonError) {
                    errorDetail = 'Failed to upload document'
                }
                throw new Error(errorDetail);
            }
            const uploadId = await response.text();
            setPlanktonSurveyId(uploadId);
            setSnackbarMessage('Document successfully uploaded');
            setSnackbarOpen(true);
            setVariant(VariantType.success);
        } catch (error) {
            setSnackbarMessage(`${error}`);
            setVariant(VariantType.error);
            throw error;
        } finally {
            setSnackbarOpen(true);
            setLoading(false);
        }
    };

    const uploadAdditionalSedimentQualityResult = async (additionalFiles: any) => {
        const formData = new FormData();
        additionalFiles.forEach((file: any) => formData.append('files', file));
        const cleanUploadId = planktonSurveyId.trim().replace(/"/g, '');
        setLoading(true);
        try {
            const response = await fetchWithAuth(`/api/SedimentQualitySurveyResult/AdditionalFiles?mainUploadId=${cleanUploadId}`, {
                method: 'POST',
                body: formData,
            });
            if (!response.ok) {
                let errorDetail = 'Failed to upload document';
                try {
                    const errorResponse = await response.json();
                    errorDetail = errorResponse.detail || errorDetail;
                } catch (jsonError) {
                    errorDetail = 'Failed to upload document'
                }
                throw new Error(errorDetail);
            }
            setSnackbarMessage('Document successfully uploaded');
            setVariant(VariantType.success);
        } catch (error) {
            setSnackbarMessage(`${error}`);
            setVariant(VariantType.error);
            throw error;
        } finally {
            setSnackbarOpen(true);
            setLoading(false);
        }
    };

    return (
        <UploadContext.Provider
            value={{
                loading,
                downloadTemplateSampleInfo,
                uploadSampleInfo,
                uploadAdditionalSampleInfo,
                downloadTemplateNutrientSurveyResult,
                uploadNutrientSurvey,
                uploadAdditionalNutrientSurvey,
                downloadTemplatePlanktonSurveyResult,
                uploadPlanktonSurvey,
                uploadAdditionalPlanktonSurvey,
                downloadTemplateSedimentQualityResult,
                uploadSedimentQualityResult,
                uploadAdditionalSedimentQualityResult
            }}>
            {children}
        </UploadContext.Provider>
    );
};

export const useUploadView = () => {
    const context = useContext(UploadContext);
    if (context === undefined) {
        throw new Error('useUploadView must be used within a UploadViewProvider');
    }
    return context;
};
