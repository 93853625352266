import { Box, CircularProgress, Backdrop } from '@mui/material'
import React from 'react'
import ReactMapGL from '../../components/layout/map/ReactMapGL'
import MapMenu from '../../components/layout/map/MapMenu'
import { useAuth } from '../../context/auth-context/AuthContext'
import { useMapView } from '../../context/map-context/mapContext'
import MapMenuButton from '../../components/feature/map-menu-button/MapMenuButton'
import Notification from '../../components/feature/notification/Notification'

const MapViewPage = () => {
  
  const { snackbarOpen, setSnackbarOpen, snackbarMessage, variant } = useAuth()
  const { loading } = useMapView()
  const alertSeverity: 'success' | 'info' | 'warning' | 'error' = (['success', 'info', 'warning', 'error'].includes(variant) ? variant : 'info') as 'success' | 'info' | 'warning' | 'error';

  return (
    <Box
      sx={{
        position: 'relative',
        width: '100%',
        height: 'calc(100vh - 65px)',
        display: 'flex',
        flexDirection: 'row',
        overflowY: 'hidden',
      }}
    >
      <MapMenu />
      <MapMenuButton />
      <ReactMapGL />
      <Notification
        open={snackbarOpen}
        message={snackbarMessage}
        severity={alertSeverity}
        onClose={() => setSnackbarOpen(false)}
      />
      {loading && (
        <Backdrop
          open={loading}
          sx={{
            color: '#fff',
            zIndex: (theme) => theme.zIndex.drawer + 1
          }}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
    </Box>
  )
}

export default MapViewPage
