import { NavLink, useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import { menuBarStyle, navLinkStyle } from './Style';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useAuth } from '../../../context/auth-context/AuthContext';

const MenuBar = () => {

    const { menuBar } = useAuth()
    const location = useLocation();
    const isTabletOrDesktop = useMediaQuery('(min-width: 1300px)');

    return (
        <Box sx={() => menuBarStyle(!isTabletOrDesktop)}>
            {menuBar?.map(res => (
                <NavLink
                    key={res.id}
                    to={res.link}
                    style={({ isActive }) => navLinkStyle(isActive, location.pathname === '/')}
                >
                    {res.title}
                </NavLink>
            ))}
        </Box>
    );
};

export default MenuBar;
