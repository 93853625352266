import React from 'react';
import { useDataPointView } from '../../../context/data-point-context/DataPointContext';
import { Box, FormControl, IconButton, InputLabel, MenuItem, Select, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useAuth } from '../../../context/auth-context/AuthContext';
import { NavLink } from 'react-router-dom';
import { useTimeseriesView } from '../../../context/timeseries-context/timeseriesContext';
import { handleHighlightLayer } from '../../../utils/MapUtils';
import { useMapView } from '../../../context/map-context/mapContext';
import chartIcon from '../../../asset/Icon/Popup/chart.svg';
import { SelectChangeEvent } from '@mui/material/Select';
import { formatDate, formatDigitValue } from '../../../utils/Utils';
import { createStyles } from './Style';

const Measurement = () => {
    const { user } = useAuth();
    const { listStationsDetails, selectedDataType, setSelectedDataType } = useDataPointView();
    const { dataTypeList, stationInfo, setStationInfo } = useTimeseriesView();
    const { layerFilter, setHighlightLayer } = useMapView();

    const theme = useTheme()
    const isXSmallScreen = useMediaQuery(theme.breakpoints.down('xs'))
    const isSmallScreen = useMediaQuery(theme.breakpoints.between('xs', 'sm'))
    const isMediumScreen = useMediaQuery(theme.breakpoints.between('sm', 'md'))
    const isLargeScreen = useMediaQuery(theme.breakpoints.between('md', 'lg'))

    const styles = createStyles(theme, isXSmallScreen, isSmallScreen, isMediumScreen, isLargeScreen)

    const getUniqueParameters = (): { name: string; displayName: string }[] => {
        const uniqueParams = new Map<string, { name: string; displayName: string }>();

        const hasRole = (role: string): boolean => {
            if (typeof user.roles === 'string') {
                return user.roles === role;
            } else if (Array.isArray(user.roles)) {
                return user.roles.includes(role);
            }
            return false;
        };
        listStationsDetails.forEach((station: any) => {
            station.parameters.forEach((param: any) => {
                if (
                    param.name !== "Battery" ||
                    (hasRole("DHIAdmin") || hasRole("SuperAdministrator"))
                ) {
                    if (!uniqueParams.has(param.name)) {
                        uniqueParams.set(param.name, {
                            name: param.name,
                            displayName: param.displayName || param.name,
                        });
                    }
                }
            });
        });
        return Array.from(uniqueParams.values());
    };

    const uniqueParameters = getUniqueParameters();

    const shortFormatDate = (dateString: string): string => {
        const date = new Date(dateString);
        const shortDate = date.toLocaleDateString(undefined, { day: '2-digit', month: 'short' });
        const shortTime = date.toLocaleTimeString(undefined, { hour: '2-digit', minute: '2-digit', hour12: false });
        return `${shortDate} ${shortTime}`;
    };

    const handleClickTimeseriesButton = (station: any, param: any) => {
        handleHighlightLayer([station.stationId], setHighlightLayer, false);
        setStationInfo({
            ...stationInfo,
            selectedPeriod: station.dataType === 'WaterQuality' ? 'Last 24 Hours' : 'Last 1 Year',
            selectedDataType: station.dataType,
            selectedStation: [station.stationId],
            selectedParameters: [param.name],
            selectedArea: station.area,
        });
    };

    const handleDataTypeChange = (event: SelectChangeEvent<string>) => {
        const newDataType = event.target.value as string;
        setSelectedDataType(newDataType);
    };

    const filteredStationDetails = listStationsDetails.filter((station: any) => {
        const stationMatches = station.stationId.toLowerCase().includes(layerFilter.toLowerCase());

        const parametersMatch = station.parameters.some((param: any) =>
            param.name.toLowerCase().includes(layerFilter.toLowerCase())
        );

        return stationMatches || parametersMatch;
    });

    return (
        <Box sx={styles.container}>
            <Box>
                <InputLabel sx={styles.dataTypeLabel}>Select Data Type:</InputLabel>
                <FormControl sx={styles.dataTypeForm}>
                    <Select
                        labelId="data-type-select-label"
                        value={selectedDataType}
                        onChange={handleDataTypeChange}
                        sx={styles.dataTypeSelect}
                    >
                        {dataTypeList.map((type: any) => (
                            <MenuItem
                                value={type.name}
                                key={type.description}
                                sx={styles.dataTypeOptions}>{type.description}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Box>
            <Box sx={styles.dataPointContainer}>
                {uniqueParameters.length > 0 ? (
                    uniqueParameters.map(({ name, displayName }) => (
                        <Box key={name} sx={styles.dataPointParameterTable}>
                            <Typography variant="subtitle2" sx={styles.dataPointParameterLabel}>
                                {displayName}
                            </Typography>
                            <Table>
                                <TableHead>
                                    <TableRow sx={styles.headerTableRow}>
                                        <TableCell sx={styles.headerTableCell}>Station</TableCell>
                                        <TableCell sx={styles.headerTableCell}>Latest</TableCell>
                                        <TableCell sx={styles.headerTableCell}>Value</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {filteredStationDetails
                                        .sort((a: any, b: any) => a.stationId.localeCompare(b.stationId))
                                        .map((station: any) => {
                                            const param = station.parameters.find((p: any) => p.name === name);
                                            return param ? (
                                                <TableRow key={station.stationId}>
                                                    <TableCell sx={styles.bodyTableRowCell}>
                                                        <Typography sx={styles.bodyLabel}>{station.stationId}</Typography>
                                                        <Tooltip title="View Timeseries">
                                                            <IconButton
                                                                component={NavLink}
                                                                to="/timeseries"
                                                                onClick={() => handleClickTimeseriesButton(station, param)}
                                                            >
                                                                <img src={chartIcon} style={styles.chartIcon} alt="timeseries-icon" />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </TableCell>
                                                    <TableCell sx={styles.bodyTableRowCell} title={formatDate(param.lastUpdatedDateTime)}>
                                                        <Typography sx={styles.bodyLabel}>{shortFormatDate(param.lastUpdatedDateTime)}</Typography>
                                                    </TableCell>
                                                    <TableCell
                                                        sx={{
                                                            ...styles.bodyTableRowCell,
                                                            color: param.isOnline
                                                                ? (param.isWithinThresholdLimits ? 'green' : 'red')
                                                                : (param.isWithinThresholdLimits ? 'grey' : 'red'),
                                                        }}
                                                    >
                                                        <Typography sx={styles.bodyLabel}>{`${formatDigitValue(param.latestValue, station.dataType)} ${param.unit}`}</Typography>
                                                    </TableCell>
                                                </TableRow>
                                            ) : null;
                                        })}
                                </TableBody>
                            </Table>
                        </Box>
                    ))
                ) : (
                    <Typography>No data available</Typography>
                )}
            </Box>
        </Box>
    );
};

export default Measurement;
