import React, { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import ProtectedRoute from './components/feature/protected-routes/ProtectedRoute'
import Header from './components/layout/header/Header';
import LandingPage from './pages/landing-page/LandingPage';
import UploadPage from './pages/upload-page/UploadPage';
import LoginPage from './pages/login-page/LoginPage';
import MapViewPage from './pages/map-view-page/MapViewPage';
import TimeSeriesPage from './pages/time-series-page/TimeSeriesPage';
import ContactUsPage from './pages/contact-us-page/ContactUsPage';
import DocumentPage from './pages/document-page/DocumentPage';
import EventPage from './pages/event-page/EventPage';
import StationPage from './pages/data-point-page/DataPointPage';
import DisclaimerPopup from './components/layout/disclaimer-popup/DisclaimerPopup';
import { Backdrop, CircularProgress } from '@mui/material';
import { useUploadView } from './context/upload-context/UploadContext';
import 'mapbox-gl/dist/mapbox-gl.css';
import './App.css';
import SilentRenewCallback from './components/feature/silent-renew-callbak/SilentRenewCallback';
import useMenuBar from './hooks/UseMenuBar';
import { useAuth } from './context/auth-context/AuthContext';

function App() {

  const { loading } = useUploadView()
  const { baseUrl, user } = useAuth()
  const { fetchMenuBar } = useMenuBar()

  useEffect(() => {
    if (baseUrl && user?.token) {
      fetchMenuBar()
    }
  }, [baseUrl, user?.token]);
  
  return (
    <div className="App">
      <Header />
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/map-view" element={
          <ProtectedRoute>
            <MapViewPage />
          </ProtectedRoute>
        } />
        <Route path="/timeseries" element={
          <ProtectedRoute>
            <TimeSeriesPage />
          </ProtectedRoute>
        } />
        <Route path="/upload" element={
          <ProtectedRoute>
            <UploadPage />
          </ProtectedRoute>
        } />
        <Route path="/document" element={
          <ProtectedRoute>
            <DocumentPage />
          </ProtectedRoute>
        } />
        <Route path="/event" element={
          <ProtectedRoute>
            <EventPage />
          </ProtectedRoute>
        } />
        <Route path="/data-point" element={
          <ProtectedRoute>
            <StationPage />
          </ProtectedRoute>
        } />
        <Route path="/contact-us" element={
          <ProtectedRoute>
            <ContactUsPage />
          </ProtectedRoute>
        } />
        <Route path="/auth/silent-callback" element={<SilentRenewCallback />} />
      </Routes>
      <DisclaimerPopup />
      <Backdrop
        sx={{
          color: '#fff',
          zIndex: 100,
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
        }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}

export default App;

